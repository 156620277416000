import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import TaskWorkflowsTable from '../components/General/TaskWorkflows/TaskWorkflowsTable';
import TaskWorkflowCreation from '../components/General/TaskWorkflows/TaskWorkflowCreation';
import { Permissions } from '../utils/Permissions';

export default function TaskWorkflowsRouting(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute path='/taskworkflows' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
        return (<TaskWorkflowsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
      }} />
      <Switch>
        <Route exact path='/taskworkflows/new' render={(props) => (<TaskWorkflowCreation resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} workshopPlanningTeams={currentIdentity.workshopPlanningTeams} outletId={props && props.location && props.location.state && props.location.state.outletId} language={currentIdentity.culture.substring(0,2)}/>)} />
        <Route exact path='/taskworkflows/:taskWorkflowId' render={(props) => {
          return (<TaskWorkflowCreation resources={resources} taskWorkflowId={props.match.params.taskWorkflowId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} workshopPlanningTeams={currentIdentity.workshopPlanningTeams} outletId={props && props.location && props.location.state && props.location.state.outletId} language={currentIdentity.culture.substring(0,2)} />)
        }} />
      </Switch>
    </>
  )
}