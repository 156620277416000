import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

export default class ChatMessageTriggerCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            moboOutletId: 0,
            type: "DOSSIER",
            trigger: "",
            language: "nl",
            content: "",
            documentURL: "",

            selectedOutletId: 0,
            loading: false,
            redirect: false,
            accessibleMoboOutlets: [],
            requestInProgress: false,
            redirect: null,
            open: true,
            chatMessageTriggerWasCreated: false
        };
    }

    componentDidMount() {
        if (this.props.moboOutletId === undefined) {
            this.setState({ moboOutletId: 0 });
        }
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/chatmessagetriggers" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };

    handleSubmit = () => {
        this.saveNewChatMessageTrigger();
    }

    saveNewChatMessageTrigger = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        if (this.state.trigger.trim().length <= 0) {
            DisplayMessage(resources["InvalidTrigger"], "error");
            return;
        }

        if (this.state.content.trim().length <= 0) {
            DisplayMessage(resources["InvalidChatMessage"], "error");
            return;
        }
        const { moboOutletId, type, trigger, language, content, documentURL } = this.state;

        var json = JSON.stringify({
            moboOutletId: moboOutletId != 0 ? moboOutletId : null,
            type: type,
            trigger: trigger,
            languageCode: language,
            content: content,
            documentURL: documentURL === "" ? null : documentURL
            
        });

        const url = `/api/mobopro/v1/chatmessagetriggers`;
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });
                    this.setState({ requestInProgress: false });
                }
                else {
                    this.setState({
                        updateInProgress: false,
                        chatMessageTriggerWasCreated: true,
                        redirect: "/chatmessagetriggers"
                    });

                    DisplayMessage(resources["ChatMessageTriggerWasSaved"], "success");
                }
            });
    }

    handleChangeOutlet = e => {
        this.setState({ moboOutletId: e.target.value });
    };

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderForm() {
        const { moboOutletId, type, trigger, language, content, documentURL } = this.state;
        var helperTextTrigger = trigger.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextContent = content.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var resources = this.props.resources;

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-outlet"
                                native
                                label={resources["Outlet"]}
                                value={moboOutletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'chatmessagetrigger-outlet',
                                }}>
                                <option key={0} value={0}>{resources["All"]}</option>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-type"
                                native
                                label={resources["Type"]}
                                value={type}
                                inputProps={{
                                    name: 'type',
                                    id: 'chatmessagetrigger-type',
                                }}>
                                <option value={'DOSSIER'} key={'dossier'}>{resources["Dossier"]}</option>
                                <option value={'ROITEM'} key={'roItem'}>{resources["ROItem"]}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextTrigger} id="chatmessagetrigger-trigger"
                                value={trigger} label={resources["Trigger"]}
                                helperText={resources["InputOfTriggerIsMandatory"]}
                                variant="outlined" name="trigger" onChange={this.handleChange} inputProps={{ maxLength: 25 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-language"
                                native
                                label={resources["Language"]}
                                value={language}
                                inputProps={{
                                    name: 'language',
                                    id: 'chatmessagetrigger-language',
                                }}>
                                <option value={'nl'} key={'nl'}>{"Nederlands"}</option>
                                <option value={'fr'} key={'fr'}>{"Français"}</option>
                                <option value={'en'} key={'en'}>{"English"} </option>
                                <option value={'de'} key={'de'}>{"Deutsch"}</option>
                                <option value={'cs'} key={'cs'}>{"Czech"}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextContent} id="chatmessagetrigger-content"
                                value={content} label={resources["ChatMessage"]}
                                helperText={resources["InputOfContentIsMandatory"]}
                                variant="outlined" name="content" onChange={this.handleChange}/>
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="chatmessagetrigger-url"
                                value={documentURL} label={resources["URL"]}
                                variant="outlined" name="documentURL" onChange={this.handleChange}/>
                        </ThemeProvider >
                    </Form.Group>

                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, chatMessageTriggerWasCreated, moboOutletId, trigger, content} = this.state;
        const { resources } = this.props;
        var isDisabled = trigger.trim().length <= 0 || content.trim().length <= 0;
        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: chatMessageTriggerWasCreated, moboOutletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-new-chatmessagetrigger" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["NewChatMessageTrigger"]}
                            </Typography>
                            <Button id="chatmessagetrigger-save" color="inherit" disabled={isDisabled} onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}