import Block from '@material-ui/icons/Block';
import React from 'react';
import { isValidColor } from '../../../utils/isValidColor';

const ColorChip = ({ colorHex, id }) => {
  if (!isValidColor(colorHex)) {
    return (
      <div id={id} className="color-card">
        <Block />
      </div>
    );
  }

  return (
    <div
      id={id}
      className="color-card"
      style={{
        backgroundColor: `#${colorHex}`,
        borderColor: "#efefef",
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    ></div>
  );
};

export default ColorChip;
