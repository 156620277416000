import { Box, CircularProgress, Dialog, Paper } from "@material-ui/core";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { OutletUserExternalCredentialsRoute } from "../../constants/routePaths";
import OutletUserExternalCredentials from "../../routing/OutletUserExternalCredentials";
import {
  createUpdateOutletUserExternalCredentials,
  getOutletUserExternalCredentialsById,
  revealOutletUserExternalCredentials,
} from "../../services/OutletUserExternalCredentials/outletUserExternalCredentialsApis";
import { externalApplications } from "../../constants/externalApplications";
import { DisplayMessage, GetSortedOptionsByName } from "../../services/helpers";
import DelayedRedirect from "../../utils/DelayedRedirect";
import { Transition } from "../../utils/ModalHelpers";
import AppBarComponent from "../Common/Headers/AppBarComponent";
import DropdownComponent from "../Common/Dropdowns/DropdownComponent";
import CheckboxComponent from "../Common/Checkboxes/CheckboxComponent";
import PasswordInputComponent from "../Common/Inputs/PasswordInputComponent";
import { Permissions } from "../../utils/Permissions";
import { decryptAes256Gcm, encryptAes256Gcm } from "../../utils/aes256";
import ModifiedByInfo from "../Common/Footers/ModifiedByInfo";
import TextFieldComponent from "../Common/Inputs/TextFieldComponent";

const ExternalCredentialsItem = ({
  resources,
  outlets,
  outletUserExternalCredentials,
  userPermissions,
  pathId,
}) => {
  const isEditMode = pathId !== undefined;
  const isDefaultAvailable = userPermissions.find(
    (x) => x.id === Permissions.AccountManagement
  );
  const [redirect, setRedirect] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [formData, setFormData] = useState({
    application: "",
    username: "",
    password: "",
    isDefault: false,
    outletId: "",
    id: outletUserExternalCredentials?.id,
  });
  const [requestIsValid, setRequestIsValid] = useState(false);
  const [modifiedBy, setModifiedBy] = useState({
    avatar: outletUserExternalCredentials?.createdByAvatar,
    on: outletUserExternalCredentials?.createdOn,
    name: outletUserExternalCredentials?.createdByName,
  });

  const isRequestValid = useCallback(
    (displayError) => {
      const { username, password, application, outletId } = formData;
      const valid =
        username.trim() !== "" &&
        password.trim() !== "" &&
        application.trim() !== "" &&
        outletId !== "";

      if (!valid && displayError) {
        if (!username) {
          DisplayMessage(resources["InvalidUsername"], "error");
        }
        if (!password) {
          DisplayMessage(resources["InvalidPassword"], "error");
        }
      }

      setRequestIsValid(valid);
      return valid;
    },
    [formData, resources]
  );

  const autoFillForm = useCallback(async () => {
    setRequestInProgress(true);

    let credentials = outletUserExternalCredentials;
    if (credentials === null || credentials === undefined) {
      const credentialsResult = await getOutletUserExternalCredentialsById(
        pathId
      );
      if (!credentialsResult) {
        setRedirect(OutletUserExternalCredentials);
        setRequestInProgress(false);
      }

      credentials = credentialsResult[0];
    }

    const passwordResult = await revealOutletUserExternalCredentials(
      parseInt(credentials?.id)
    );
    if (!passwordResult) {
      setRedirect(OutletUserExternalCredentials);
    }

    const decryptedPassword = await decryptAes256Gcm(passwordResult.password);
    setFormData({
      outletId: credentials?.outletId,
      application: credentials?.application,
      username: credentials?.username,
      isDefault: credentials?.isDefault,
      id: credentials?.id,
      password: decryptedPassword,
    });
    setModifiedBy({
      avatar: credentials?.createdByAvatar,
      on: credentials?.createdOn,
      name: credentials?.createdByName,
    });

    setRequestInProgress(false);
  }, [outletUserExternalCredentials, pathId]);

  useEffect(() => {
    if (isEditMode) {
      autoFillForm();
    }
    if (!outlets) {
      setRedirect(OutletUserExternalCredentialsRoute);
    }
  }, [isEditMode, autoFillForm, outlets]);

  useEffect(() => {
    isRequestValid(false);
  }, [formData, isRequestValid]);

  const handleClose = () => {
    setDialogOpen(false);
    setRedirect(OutletUserExternalCredentialsRoute);
  };

  const handleSubmit = async () => {
    if (!isRequestValid(true)) return;

    setRequestInProgress(true);
    const { username, password, isDefault, outletId, application, id } =
      formData;

    const encryptedPassword = await encryptAes256Gcm(password);
    const body = JSON.stringify({
      username,
      password: encryptedPassword,
      isDefault,
      outletId,
      application,
      id,
    });

    const result = await createUpdateOutletUserExternalCredentials(body);

    if (result) {
      setRefreshGrid(true);
      const successMessage = isEditMode
        ? resources["ExternalCredentialsUpdatedWithSuccess"]
        : resources["ExternalCredentialsCreatedWithSuccess"];
      DisplayMessage(successMessage, "success");
    }
    setRedirect(OutletUserExternalCredentialsRoute);
  };

  const applicationOptions = useMemo(
    () => [
      {
        name: resources["DBB"],
        key: externalApplications.DBB,
      },
    ],
    [resources]
  );

  const outletOptions = useMemo(
    () =>
      outlets.map((outlet) => ({
        name: outlet.description,
        key: outlet.id,
      })),
    [outlets]
  );

  const handleFormChange = (key, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <Box>
      {redirect && (
        <DelayedRedirect
          to={{ pathname: redirect, state: { refreshGrid: refreshGrid } }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          idName="externalCredentials"
          title={
            isEditMode
              ? resources["EditExternalCredentials"]
              : resources["NewExternalCredentials"]
          }
          resources={resources}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          disabled={requestInProgress || !requestIsValid}
        />
        {requestInProgress ? (
          <Box className="centered-loader fadeIn">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="container fadeIn">
            <Box className="py-4 grey">
              <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Box className="d-flex form-group pt-3">
                  <Box className="col-3" />
                  <Box className="col-6">
                    <TextFieldComponent
                      value={formData.username}
                      handleChange={(e) =>
                        handleFormChange("username", e.target.value)
                      }
                      labelText={resources["UserName"]}
                      id="username"
                      maxLength={50}
                      placeholderText={resources["InsertUsername"]}
                      helperText={resources["InputOfUsernameIsMandatory"]}
                    />
                    <PasswordInputComponent
                      value={formData.password}
                      handleChange={(e) =>
                        handleFormChange("password", e.target.value)
                      }
                      placeholder={resources["InsertPassword"]}
                      labelText={resources["Password"]}
                      id="password"
                      maxLength={64}
                      helperText={resources["InputOfPasswordIsMandatory"]}
                      isPassword={true}
                    />
                    <DropdownComponent
                      value={formData.application}
                      handleChange={(e) =>
                        handleFormChange("application", e.target.value)
                      }
                      options={GetSortedOptionsByName(applicationOptions)}
                      labelText={resources["Application"]}
                      id="application"
                      disabled={isEditMode}
                      placeholder={resources["SelectApplication"]}
                      helperText={isEditMode ? "" : resources["InputOfApplicationIsMandatory"]}
                      isMandatory
                    />
                    <DropdownComponent
                      value={formData.outletId}
                      handleChange={(e) =>
                        handleFormChange("outletId", parseInt(e.target.value))
                      }
                      options={GetSortedOptionsByName(outletOptions)}
                      labelText={resources["Outlet"]}
                      id="outletId"
                      disabled={isEditMode}
                      placeholder={resources["SelectOutlet"]}
                      helperText={isEditMode ? "" : resources["InputOfOutletIsMandatory"]}
                      isMandatory
                    />
                    {isDefaultAvailable && (
                      <CheckboxComponent
                        label={resources["IsDefault"]}
                        id="IsDefault"
                        checked={formData.isDefault}
                        onChange={(e) =>
                          handleFormChange("isDefault", e.target.checked)
                        }
                      />
                    )}
                  </Box>
                  <Box className="col-3" />
                </Box>
                {isEditMode && (
                  <ModifiedByInfo
                    resources={resources}
                    modifiedByAvatar={modifiedBy.avatar}
                    modifiedOn={modifiedBy.on}
                    modifiedByName={modifiedBy.name}
                    isCreated={false}
                  />
                )}
              </Paper>
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default ExternalCredentialsItem;
