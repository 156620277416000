import React from "react";
import { RestrictedRoute } from "../utils/RestrictedRoute";
import { Permissions } from "../utils/Permissions";
import { Switch, Route } from "react-router";
import AssetClassItem from "../components/Drive/AssetClasses/AssetClassItem";
import AssetClassesTable from "../components/Drive/AssetClasses/AssetClassesTable";
import { NewAssetClassRoute } from "../constants/routePaths";

export default function AssetClassesRouting(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute
        path="/assetclasses"
        userPermissions={currentIdentity.permissions}
        routePermissions={[Permissions.AccessToDriveSettings]}
        resources={resources}
        render={(props) => {
          return (
            <AssetClassesTable
              resources={resources}
              refreshGrid={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.refreshGrid
              }
            />
          );
        }}
      />
      <Switch>
        <Route
          exact
          path={NewAssetClassRoute}
          render={(props) => (
            <AssetClassItem
              resources={resources}
              assetGroupId={props?.location?.state?.assetGroupId}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/assetclasses/:assetClassId"
          render={(props) => (
            <AssetClassItem
              resources={resources}
              assetClassId={props.match.params.assetClassId}
            />
          )}
        />
      </Switch>
    </>
  );
}
