import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class ChatMessageTriggerEditing extends Component {
    constructor(props) {
        super(props);

        var triggerId = undefined;

        if (this.props !== undefined) {
            triggerId = this.props.triggerId;
        }

        this.state = {
            id: triggerId,
            moboOutletId: this.props.outletId !== undefined ? parseInt(this.props.outletId) : 0,
            moboOutletDescription: "",
            type: "",
            trigger: "",
            content: "",
            language: "nl",
            documentURL: "",
            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",

            redirect: false,
            accessibleMoboOutlets: [],
            requestInProgress: false,
            redirect: null,
            open: true,
            chatMessageTriggerWasModified: false,
            editedTriggerId: triggerId,
        };
    }

    componentDidMount() {
       if (this.state.editedTriggerId !== undefined) {
        this.getChatMessageTriggerById();
    }
    };

    handleClose = () => {
        this.setState({ open: false, redirect: "/chatmessagetriggers" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = () => {
        this.saveEditedChatMessageTrigger();
    };

    getChatMessageTriggerById = () => {

        fetch(`/api/mobopro/v1/chatmessagetriggers`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                } else {
                    response.json().then(result => {
                        let groupItem = [];
                        let editedTriggerId = parseInt(this.state.editedTriggerId);
                        result.forEach(function (rowData) {
                            if (rowData.id === editedTriggerId) {
                                groupItem = rowData;
                            }
                        });

                        this.setState({
                            id: groupItem.id,
                            moboOutletId: groupItem.moboOutletId == null ? 0 : groupItem.moboOutletId,
                            moboOutletDescription: groupItem.moboOutletDescription,
                            type: groupItem.type,
                            trigger: groupItem.trigger,
                            content: groupItem.content,
                            language: groupItem.languageCode,
                            documentURL: groupItem.documentURL,
                            modifiedOn: groupItem.modifiedOn,
                            modifiedByName: groupItem.modifiedByName,
                            modifiedByAvatar: groupItem.modifiedByAvatar,
                            loading: false,
                        });
                        return;
                    });
                }
            });
    };

    saveEditedChatMessageTrigger = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        if (this.state.trigger.trim().length <= 0) {
            DisplayMessage(resources["InvalidTrigger"], "error");
            return;
        }

        if (this.state.content.trim().length <= 0) {
            DisplayMessage(resources["InvalidChatMessage"], "error");
            return;
        }

        const { trigger, language, content, documentURL } = this.state;
        var json = JSON.stringify({
            trigger: trigger,
            languageCode: language,
            content: content,
            documentURL: documentURL === "" ? null : documentURL,
        });

        const url = `/api/mobopro/v1/chatmessagetriggers/${this.state.id}`;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });

                }
                this.setState({ requestInProgress: false });
                return response.json();
            })
            .then(result => {
                if (result.id !== undefined) {
                    this.setState({
                        updateInProgress: false,
                        chatMessageTriggerWasModified: true,
                        redirect: "/chatmessagetriggers"
                    });

                    DisplayMessage(resources["ChatMessageTriggerWasEdited"], "success");
                }
            });
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }


    renderForm() {
        const { type, trigger, content, language, documentURL, moboOutletId } = this.state;
        var helperTextTrigger = trigger.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextContent = content.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var resources = this.props.resources;

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl disabled className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-edit-outlet"
                                native
                                label={resources["Outlet"]}
                                value={moboOutletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'chatmessagetrigger-edit-outlet',
                                }}>
                                 <option key={0} value={0}>{resources["All"]}</option>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl disabled className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-edit-type"
                                native
                                label={resources["Type"]}
                                value={type}
                                inputProps={{
                                    name: 'type',
                                    id: 'chatmessagetrigger-edit-type',
                                }}>
                                <option value={'DOSSIER'} key={'dossier'}>{resources["Dossier"]}</option>
                                <option value={'ROITEM'} key={'roItem'}>{resources["ROItem"]}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextTrigger} id="chatmessagetrigger-edit-trigger"
                                value={trigger} label={resources["Trigger"]}
                                helperText={resources["InputOfTriggerIsMandatory"]}
                                variant="outlined" name="trigger" onChange={this.handleChange} inputProps={{ maxLength: 25 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="chatmessagetrigger-edit-language"
                                native
                                label={resources["Language"]}
                                value={language}
                                inputProps={{
                                    name: 'language',
                                    id: 'chatmessagetrigger-edit-language',
                                }}>
                                <option value={'nl'} key={'nl'}>{"Nederlands"}</option>
                                <option value={'fr'} key={'fr'}>{"Français"}</option>
                                <option value={'en'} key={'en'}>{"English"} </option>
                                <option value={'de'} key={'de'}>{"Deutsch"}</option>
                                <option value={'cs'} key={'cs'}>{"Czech"}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextContent} id="chatmessagetrigger-edit-content"
                                value={content} label={resources["ChatMessage"]}
                                helperText={resources["InputOfContentIsMandatory"]}
                                variant="outlined" name="content" onChange={this.handleChange} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="chatmessagetrigger-edit-url"
                                value={documentURL} label={resources["URL"]}
                                variant="outlined" name="documentURL" onChange={this.handleChange} />
                        </ThemeProvider >
                    </Form.Group>
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }


    render() {
        const { redirect, open, chatMessageTriggerWasModified, outletId, trigger, content } = this.state;
        const { resources } = this.props;
        var isDisabled = trigger.trim().length <= 0 || content.trim().length <= 0;
        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: chatMessageTriggerWasModified, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-edit-chatmessagetrigger" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["Edit"]}
                            </Typography>
                            <Button id="chatmessagetrigger-update" color="inherit" disabled={isDisabled} onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}