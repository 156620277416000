import { Box } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { default as React, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { deleteAssetClass } from "../../../services/AssetClasses/assetClassApisService";
import { getAssetClasses } from "../../../services/AssetClasses/assetClassApisService.js";
import { getAssetGroups } from "../../../services/AssetGroups/assetGroupApisService.js";
import { DisplayMessage } from "../../../services/helpers";
import { useStyles } from "../../../styles/styles";
import AddItemButton from "../../Common/Buttons/AddItemButton.js";
import Header from "../../Common/Headers/Header.js";
import DeleteConfirmationModal from "../../Common/Modals/DeleteConfirmationModal.js";
import TableComponent from "../../Common/Table/TableComponent.js";
import DropdownComponent from "./../../Common/Dropdowns/DropdownComponent";
import { GetSortedOptionsByName } from "./../../../services/helpers";
import { sortTableBooleans, sortTableStringsNotCaseSensitive } from "../../../utils/tableSorting.js";

const AssetClassesTable = ({ resources, refreshGrid }) => {
  const classes = useStyles();
  const [allAssetClasses, setAllAssetClasses] = useState([]);
  const [assetClasses, setAssetClasses] = useState([]);
  const [assetGroups, setAssetGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAssetClassId, setSelectedAssetClassId] = useState(null);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState(0);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const history = useHistory();

  const tableSettings = {
    actions: [
      {
        icon: () => <Edit />,
        tooltip: resources["Edit"],
        onClick: (event, rowData) => {
          history.push({
            pathname: `/assetclasses/${rowData.id}`,
            state: { assetClassId: rowData.id },
          });
        },
      },
      {
        icon: () => <DeleteOutline />,
        tooltip: resources["Delete"],
        onClick: (event, rowData) => handleDelete(rowData.id),
      },
    ],
    columns: [
      {
        title: resources["Code"],
        field: "code",
        cellStyle: { width: "20%", minWidth: "150px" },
        headerStyle: { width: "20%", minWidth: "150px" },
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.code, b.code)
      },
      {
        title: resources["Description"],
        field: "description",
        cellStyle: { width: "35%" },
        headerStyle: { width: "40%" },
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.description, b.description)
      },
      {
        title: resources["Group"],
        field: "assetGroupName",
        cellStyle: { width: "35%" },
        headerStyle: { width: "40%" },
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.assetGroupName, b.assetGroupName)
      },
      {
        title: resources["IsActive"],
        field: "isActive",
        cellStyle: { width: "10%", minWidth: "150px" },
        headerStyle: { width: "10%" },
        customSort: (a, b) => sortTableBooleans(a.isActive, b.isActive)
      },
    ],
  };

  const handleDelete = (id) => {
    setSelectedAssetClassId(id);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedAssetClassId(null);
    setDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    if (selectedAssetClassId) {
    const result = await deleteAssetClass(selectedAssetClassId);

      if (result) {
        setAssetClasses((prev) =>
          prev.filter((ac) => ac.id !== selectedAssetClassId)
        );
        DisplayMessage(resources["AssetClassWasDeletedWithSuccess"], "success");
      }
    }
    closeDeleteModal();
  };

  const fetchAssetGroups = async () => {
    const data = await getAssetGroups(true);
    setAssetGroups(
      data?.map(({ id, description }) => ({ key: id, name: description })) || []
    );
  };

  const filterAssetClasses = useCallback(
    (groupId) => {
      if (groupId > 0) {
        setAssetClasses(
          allAssetClasses.filter((ac) => ac.assetGroupId === groupId)
        );
      } else {
        setAssetClasses(allAssetClasses);
      }
    },
    [allAssetClasses]
  );

  const fetchAssetClasses = useCallback(async () => {
    setLoading(true);
    const data = await getAssetClasses();
    if (data) {
      const formattedData = data.map(
        ({ id, code, description, isActive, assetGroup }) => ({
          id,
          code,
          description,
          isActive,
          assetGroupId: assetGroup?.id,
          assetGroupName: assetGroup?.description,
        })
      );
      setAllAssetClasses(formattedData);
      setAssetClasses(formattedData);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    filterAssetClasses(selectedAssetGroupId);
  }, [filterAssetClasses, selectedAssetGroupId]);

  useEffect(() => {
    if (isFirstTime || refreshGrid) {
      fetchAssetGroups();
      fetchAssetClasses();
      setIsFirstTime(false);
    }
  }, [fetchAssetClasses, isFirstTime, refreshGrid]);

  const getAssetGroupsOptions = () => {
    const filteredAssetGroups = GetSortedOptionsByName(assetGroups);
    filteredAssetGroups.unshift({ key: 0, name: resources["All"] });
    return filteredAssetGroups;
  };

  return (
    <Box>
      <Box className="position-relative py-4">
        <Header title={resources["AssetClasses"]} loading={loading} />
        {!loading && (
          <>
            <Box className="d-flex form-group">
              <DropdownComponent
                value={selectedAssetGroupId}
                handleChange={(e) =>
                  setSelectedAssetGroupId(parseInt(e.target.value))
                }
                options={getAssetGroupsOptions(assetGroups)}
                labelText={resources["AssetGroup"]}
                id="grid-groups"
              />
              <AddItemButton
                title={resources["AddAssetClass"]}
                linkPath={"/assetclasses/new"}
                state={{ assetGroupId: selectedAssetGroupId }}
                idName="class"
              />
            </Box>
            <TableComponent
              resources={resources}
              data={assetClasses}
              tableSettings={tableSettings}
            />
          </>
        )}
        <DeleteConfirmationModal
          onConfirm={confirmDelete}
          resources={resources}
          areYouSureMessage={resources["AreYouSureYouWantToDeleteAssetClass"]}
          open={deleteModalOpen}
          onClose={closeDeleteModal}
          className={classes.modal}
        />
      </Box>
    </Box>
  );
};

export default AssetClassesTable;
