import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';

import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Loader from '../../../Loader.js';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import { CircularProgress, FormControl, InputLabel, Paper, Select } from '@material-ui/core';
import moment from 'moment';
import { sortTableDates, sortTableStringsNotCaseSensitive } from '../../../../utils/tableSorting.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class LinkAgendasTable extends Component {
    constructor(props) {
        super(props);

        var outlets = [];
        if (this.props.accessibleMoboOutlets !== undefined) {
            outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
        }

        this.state = {
            dataGrid: [],
            outletId: this.props.accessibleMoboOutlets !== undefined ? this.props.accessibleMoboOutlets[0].id : 0,
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
            editedLinkAgendaId: undefined,
            editedLinkAgenda: false,
            requestInProgress: false,
            linkAgendasWasCreated: false,
            redirect: null,
            outletUserName: "",
            activeFrom: undefined,
            activeTill: undefined,
            linkAgendasItems: [],
            agendas: [],
            outletUsers: [],
            outlets: outlets
        };
    }

    componentDidMount() {
        if (this.state.outletId > 0) {
            this.getData(this.state.outletId);
        }
        else {
            if (this.state.outlets[0] !== undefined) {
                this.getData(this.state.outlets[0].id);
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData(this.state.outletId);
        }
        this.setState({ redirect: null });
    }

    handleChangeOutlet = e => {
        var id = e.target.value
        this.setState({ outletId: id }, () => this.getData(id));
    };

    handleChange = e => {
        if (this.state.outletId === 0) {
            var outlets = this.state.outlets;
            this.setState({ [e.target.name]: e.target.value, outletId: outlets[0].id });
            return;
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    getData = (outletId) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { 
                title: resources["OutletUser"],
                field: 'outletUserName',
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableStringsNotCaseSensitive(a.outletUserName, b.outletUserName)
            },
            {
                title: resources["StartOn"],
                field: 'activeFrom',
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableDates(a.activeFrom, b.activeFrom)
            },
            {
                title: resources["EndsOn"],
                field: 'activeTill',
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableDates(a.activeTill, b.activeTill)
            },
        ];

        var actions = [
            {
                icon: (rowData) => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => {
                    this.setState({
                        redirect: `linkagendas/${this.state.outletId}/${rowData.id}`, editedLinkAgendaId: rowData.id, editedLinkAgenda: this.state.agendaItems,
                        agendas: this.state.agendas, outletUsers: this.state.outletUsers
                    })
                }
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];

        initialData.actions = actions;
        fetch(`/api/mobopro/v1/linkedworkshopagendas?moid=${outletId}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                        this.setState({ fetchingData: false, dataGrid: [] })
                    }
                    else {
                        const currentRows = [];
                        var agendaItems = [];

                        data.forEach(function (rowData) {
                            agendaItems.push(rowData);

                            currentRows.push({
                                id: rowData.id,
                                outletUserName: rowData.outletUser !== null ? rowData.outletUser.firstName + " " + rowData.outletUser.lastName : "",
                                activeFrom: moment(rowData.activeFrom).format('DD/MM/YYYY'),
                                activeTill: rowData.activeTill !== null ? moment(rowData.activeTill).format('DD/MM/YYYY') : "",
                            });
                        });

                        initialData.data = currentRows;

                        this.setState({
                            dataGrid: initialData,
                            fetchingData: false,
                            linkAgendasItems: agendaItems
                        });
                    }
                })
            })
    }

    getOutletUsers() {
        var url = '/api/mobopro/v1/outletusers?includedeleted=false';
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    }
                    else {
                        const outletUsersInfo = [];
                        data.forEach(function (item) {
                            var outletUserName = item.firstName + " " + item.lastName;
                            outletUsersInfo.push({
                                id: item.id,
                                name: outletUserName
                            });
                        });

                        if (outletUsersInfo != undefined) {
                            var outletUsersInfoSorted = outletUsersInfo.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
                            this.setState({ outletUsers: outletUsersInfoSorted });
                        }
                    }
                })
            });
    }

    getAgendas() {
        var url = '/api/mobopro/v1/workshopagendas';
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(data => {

                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    }
                    else {
                        const agendas = [];
                        data.forEach(function (item) {
                            agendas.push({
                                id: item.id,
                                description: item.description
                            });
                        });

                        if (agendas != undefined) {
                            var agendasSorted = agendas.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
                            this.setState({ agendas: agendasSorted });
                        }
                    }
                })

            });
    }

    handleEditAgenda = (itemId, editedLinkAgendaDialog) => {
        if (editedLinkAgendaDialog === false) { this.getData(this.state.outletId); }
        this.setState({ editedLinkAgenda: editedLinkAgendaDialog, editedLinkAgendaId: itemId });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleDeleteAgenda = () => {
        let resources = this.props.resources;
        this.setState({ requestInProgress: true });
        const url = "/api/mobopro/v1/linkedworkshopagendas/" + this.state.deletedItem;
        fetch(url, {
            method: 'DELETE',
            headers: new Headers({
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        }).then(response => {

            if (response.ok) {
                DisplayMessage(resources["LinkAgendaWasDeleted"], "success");
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.getData(this.state.outletId);
            }
            else {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                })
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
            }
        });
    }

    renderOutlets = () => {
        if (this.state.outlets[0] === undefined) return;
        var outlets = this.state.outlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    handleAddNewLinkAgenda = () => {
        this.getOutletUsers();
        this.getAgendas();
        this.setState({ redirect: `linkagendas/new`, editedLinkAgendaId: undefined });
    }

    render() {
        let resources = this.props.resources;
        const { redirect, editedLinkAgendaId, outletId, dataGrid, fetchingData, requestInProgress, linkAgendasItems, outletUsers, agendas } = this.state;
        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedLinkAgendaId: editedLinkAgendaId, outletId: outletId, linkAgendasItems: linkAgendasItems, outletUsers: outletUsers, agendas: agendas, refreshGrid: true }
            }} />
        }


        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["LinkAgendas"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>

                    <div className={(fetchingData ? 'fadeOut' : 'fadeIn')}>

                        <div className="float-left mt-3 col-md-4 pl-0">
                            <FormControl style={{ minWidth: "120" }} className="mb-4 col-md-11" variant="outlined">
                                <InputLabel htmlFor="outlined-outlets">{resources["Outlet"]} </InputLabel>
                                <Select
                                    label={resources["Outlet"]}
                                    native
                                    value={outletId}
                                    name="outletId"
                                    inputProps={{
                                        name: 'outletId',
                                        id: 'outlined-outlets',
                                    }}
                                    onChange={this.handleChangeOutlet}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="float-right mt-3">
                            <div className="float-right mb-3" >
                                <Button id="addNew" onClick={this.handleAddNewLinkAgenda} color="primary" variant="contained" >
                                    <i className="fas fa-plus mr-2" title={resources["AddNewLinkAgendatitle"]} />
                                    {resources["AddNewLinkAgenda"]}
                                </Button>
                            </div>
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}

                                    data={dataGrid.data}
                                    columns={dataGrid.columns}
                                    actions={dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20], draggable: true }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteLinkAgenda"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button id="btnCancelDelete" className="btn btn-secondary" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} onClick={this.handleCloseDelete}
                                disabled={requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={this.handleDeleteAgenda}
                                disabled={requestInProgress}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}