import moment from "moment";

export const sortTableDates = (date1, date2, format = "DD/MM/YYYY") => {
  const parsedDate1 = moment(date1, format);
  const parsedDate2 = moment(date2, format);

  if (!parsedDate1.isValid() && parsedDate2.isValid()) {
    return -1;
  }

  if (parsedDate1.isValid() && !parsedDate2.isValid()) {
    return 1;
  }

  if (!parsedDate1.isValid() && !parsedDate2.isValid()) {
    return 0;
  }

  if (parsedDate1.isBefore(parsedDate2)) {
    return -1;
  }

  if (parsedDate1.isAfter(parsedDate2)) {
    return 1;
  }

  return 0;
};

export const sortTableNumbers = (number1, number2) => {
    const isNumber1Valid = number1 !== null && number1 !== undefined;
    const isNumber2Valid = number2 !== null && number2 !== undefined;

    if(!isNumber1Valid && isNumber2Valid){
        return -1;
    }

    if(isNumber1Valid && !isNumber2Valid){
        return 1;
    }

    if(!isNumber1Valid && !isNumber2Valid){
        return 0;
    }

    if(number1 < number2){
        return -1;
    }

    if(number1 > number2){
        return 1;
    }
    
    if(number1 === number2){
        return 0;
    }
}

export const sortTableStringsNotCaseSensitive = (text1, text2) => {
    if(!stringHasValue(text1) && stringHasValue(text2)){
        return -1;
    }

    if(stringHasValue(text1) && !stringHasValue(text2))
    {
        return 1;
    }

    if(!stringHasValue(text1) && !stringHasValue(text2))
    {
        return 0;
    }

    return text1.toLowerCase().trim().localeCompare(text2.toLowerCase().trim())
}

export const sortTableColors = (color1, color2) => {
    if(!stringHasValue(color1) && stringHasValue(color2)){
        return -1;
    }

    if(stringHasValue(color1) && !stringHasValue(color2))
    {
        return 1;
    }

    if(!stringHasValue(color1) && !stringHasValue(color2))
    {
        return 0;
    }

    return -color1.toLowerCase().trim().localeCompare(color2.toLowerCase().trim())
}

const stringHasValue = (text) => {
    return text !== null && text !== undefined && text.trim() !== '';
}

export const sortTableBooleans = (value1, value2) => {
    const isValue1Valid = value1 !== null && value1 !== undefined;
    const isValue2Valid = value2 !== null && value2 !== undefined;

    if(!isValue1Valid && isValue2Valid){
        return -1;
    }

    if(isValue1Valid && !isValue2Valid)
    {
        return 1;
    }
    
    if(!isValue1Valid && !isValue2Valid){
        return 0;
    }

    if(value1 === false && value2 === true){
        return -1;
    }

    if(value1 === true && value2 === false){
        return 1;
    }

    return 0;
}
