import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../Loader.js';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../utils/utils.js';
import { DisplayMessage } from '../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import { DeleteForever } from '@material-ui/icons';
import { sortTableBooleans, sortTableStringsNotCaseSensitive } from '../../../utils/tableSorting.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class OutletUsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          dataGrid: [],
          currentOutletUserId: this.props.currentOutletUserId,
          outletId: 0,
          fetchingData: true,
          showDelete: false,
          showUndelete: false,
          deletedItem: 0,
          undeletedItem: 0,
          accessibleMoboOutlets: [],
          editedOutletUserId: undefined,
          editedOutletUser: undefined,
          showEditOutletUser: false,
          requestInProgress: false,
          refreshGrid: false,
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData();
        }
        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;

        initialData.columns = [
            { 
                title: resources["Name"],
                width: "45%",
                field: 'name',
                filtering: false,
                customSort: (a, b) => sortTableStringsNotCaseSensitive(a.name, b.name) 
            },
            {
                title: resources["IsActive"], field: 'isActive', filtering: true,
                lookup: { true: resources["Yes"], false: resources["No"] },
                customSort: (a, b) => sortTableBooleans(a.isActive, b.isActive)
            },
            {
                title: resources["IsDeleted"], field: 'isDeleted', filtering: true,
                lookup: { true: resources["Yes"], false: resources["No"] },
                customSort: (a, b) => sortTableBooleans(a.isDeleted, b.isDeleted)
            }
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `outletusers/${rowData.id}`, editedOutletUserId: rowData.id, })
            },

            rowData => (!rowData.isDeletedValue && rowData.id === this.state.currentOutletUserId ? {
                icon: () => <DeleteForever />,
                tooltip: resources["YouCantDeleteCurrentUser"],

            } : null),

            rowData => (!rowData.isDeletedValue && rowData.id !== this.state.currentOutletUserId ? {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: () => this.handleShowDelete(rowData.id),

            } : null),
            rowData => (rowData.isDeletedValue && rowData.id !== this.state.currentOutletUserId ? {
                icon: () => <RestoreFromTrash />,
                tooltip: resources["UnDelete"],
                onClick: () => this.handleShowUndelete(rowData.id),
            } : null),
        ]

        fetch(`/api/mobopro/v1/outletusers`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (rowData) {
                    currentRows.push({
                        id: rowData.id,
                        name: (rowData.firstName + " " + rowData.lastName).trim(),
                        isActive: rowData.isActive,
                        isActiveValue: rowData.isActive,
                        isDeleted: rowData.isDeleted,
                        isDeletedValue: rowData.isDeleted,
                    });
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: 0 });
    }

    handleCloseUndelete = () => {
        this.setState({ showUndelete: false, undeletedItem: 0 });
    }

    handleEditOutletUser = (itemId, showEditOutletUserDialog) => {

        if (showEditOutletUserDialog === false) { this.getData(this.state.outletId); }
        this.setState({ showEditOutletUser: showEditOutletUserDialog, editedOutletUserId: itemId });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleShowUndelete = (itemId) => {
        this.setState({ showUndelete: true, undeletedItem: itemId });
    }


    handleDeleteOutletUser = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/outletuser/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["OutletUserWasDeleted"], "success");
                this.getData();
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();

            });
        }
    }

    handleUndeleteOutletUser = () => {
        if (this.state.undeletedItem > 0) {

            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/undeleteoutletuser/" + this.state.undeletedItem;
            fetch(url, {
                method: 'PUT',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["OutletUserWasUnDeleted"], "success");
                this.getData();
                this.setState({ showUndelete: false, undeletedItem: 0, requestInProgress: false });
            });
        }
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    render() {
        const { redirect, fetchingData } = this.state;
        const { resources } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedOutletUserId: this.state.editedOutletUserId, refreshGrid: false }
            }}
            />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["OutletUsersTitle"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/outletusers/new", state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-OutletUser" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewOutletUser"]} />
                                    {resources["AddNewOutletUser"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 15, 20],
                                        filtering: true,
                                        debounceInterval: 700
                                    }}

                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteOutletUser"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteOutletUser} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                    <Rodal visible={this.state.showUndelete} onClose={this.handleCloseUndelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToUnDeleteOutletUser"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelUndelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseUndelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnUndelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleUndeleteOutletUser} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>
                </div>
            </div >
        )
    };
}