import React, { useState, useEffect } from "react";
import { fetchWorkPreparationRoles } from "../../../../services/OutletUsers/outletUserService.js";
import DropdownComponent from './../../../Common/Dropdowns/DropdownComponent';

const WorkPreparationSelection = ({
  handleChange,
  value,
  inputLabel,
  optionSelectText,
  disabled = false,
}) => {
  const [workPreparationRoles, setWorkPreparationRoles] = useState([]);

  const getWorkPreparationRoles = async () => {
    const workPreparationRolesSorted = await fetchWorkPreparationRoles();
    setWorkPreparationRoles(workPreparationRolesSorted);
  };

  useEffect(() => {
    if(!disabled)
    {
      getWorkPreparationRoles();
    }
  }, [disabled]);

  const options = [
    ...workPreparationRoles.map((role) => ({
      key: role.id,
      name: role.description,
    })),
  ];

  return (
    <DropdownComponent
      value={value}
      handleChange={handleChange}
      options={options}
      labelText={inputLabel}
      id="workPreparation-role"
      disabled={disabled}
      placeholder={optionSelectText}
    />
  );
};

export default WorkPreparationSelection;
