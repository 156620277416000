import { Box } from "@material-ui/core";
import { DeleteOutline, Edit, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { DisplayMessage } from "../../services/helpers";
import { useStyles } from "../../styles/styles";
import TableComponent from "../Common/Table/TableComponent";
import DeleteConfirmationModal from "../Common/Modals/DeleteConfirmationModal";
import AddItemButton from "../Common/Buttons/AddItemButton";
import Header from "../Common/Headers/Header";
import { useHistory } from "react-router-dom";
import {
  deleteOutletUserExternalCredentials,
  getOutletUserExternalCredentials,
  revealOutletUserExternalCredentials,
} from "../../services/OutletUserExternalCredentials/outletUserExternalCredentialsApis";
import { decryptAes256Gcm } from "../../utils/aes256";
import { sortTableStringsNotCaseSensitive } from "../../utils/tableSorting";

const ExternalCredentialsTable = ({ resources, refreshGrid, outlets }) => {
  const classes = useStyles();
  const [outletUserExternalCredentials, setOutletUserExternalCredentials] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCredentialsId, setSelectedCredentialsId] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [revealedPasswords, setRevealedPasswords] = useState([]);
  const history = useHistory();

  const tableSettings = {};

  tableSettings.actions = [
    {
      icon: () => <Edit />,
      tooltip: resources["Edit"],
      onClick: (event, rowData) => {
        history.push({
          pathname: `/outletuserexternalcredentials/${rowData.id}`,
          state: {
            outletUserExternalCredentials: outletUserExternalCredentials.find(
              (entry) => entry.id === rowData.id
            ),
          },
        });
      },
    },
    {
      icon: () => <DeleteOutline />,
      tooltip: resources["Delete"],
      onClick: (event, rowData) => handleDelete(rowData.id),
    },
    {
      icon: () => <Visibility />,
      tooltip: resources["VisibilityControl"],
      onClick: (event, rowData) => {
        handlePasswordVisibility(rowData.id);
      },
    },
  ];

  tableSettings.columns = [
    {
      title: resources["Outlet"],
      field: "outletName",
      cellStyle: {
        width: "25%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "25%",
        minWidth: "150px",
      },
      customSort: (a, b) => sortTableStringsNotCaseSensitive(a.outletName, b.outletName)
    },
    {
      title: resources["UserName"],
      field: "username",
      cellStyle: {
        width: "25%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "25%",
        minWidth: "150px",
      },
      customSort: (a, b) => sortTableStringsNotCaseSensitive(a.username, b.username)
    },
    {
      title: resources["Password"],
      field: "password",
      cellStyle: {
        width: "25%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "25%",
        minWidth: "150px",
      },
      customSort: (a, b) => sortTableStringsNotCaseSensitive(a.password, b.password)
    },
    {
      title: resources["Application"],
      field: "application",
      cellStyle: {
        width: "25%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "25%",
        minWidth: "150px",
      },
      customSort: (a, b) => sortTableStringsNotCaseSensitive(a.application, b.application)
    },
  ];

  const canCreateCredentials = () => {
    return outlets.some(
      (o) => !outletUserExternalCredentials.some((obj) => obj.outletId === o.id)
    );
  };

  const getAvailableOutlets = () => {
    return outlets.filter(
      (o) => !outletUserExternalCredentials.some((obj) => obj.outletId === o.id)
    );
  };

  const handlePasswordVisibility = async (id) => {
    if (revealedPasswords.includes(id)) {
      hidePassword(id);
    } else {
      await handleRevealPassword(id);
    }
  };

  const handleRevealPassword = async (id) => {
    const result = await tryToRevealPassword(id);
    if (result) {
      setRevealedPasswords((prev) => [...prev, id]);
    }
  };

  const tryToRevealPassword = async (id) => {
    if (!id) return null;
    const result = await revealOutletUserExternalCredentials(id);
    if (result) {
      const password = await decryptAes256Gcm(result.password);
      setOutletUserExternalCredentials((prevCredentials) =>
        prevCredentials.map((credentials) =>
          credentials.id === id
            ? { ...credentials, password: password }
            : credentials
        )
      );
    }
    return result;
  };

  const hidePassword = (id) => {
    setOutletUserExternalCredentials((prevCredentials) =>
      prevCredentials.map((credentials) =>
        credentials.id === id
          ? { ...credentials, password: "*********" }
          : credentials
      )
    );
    setRevealedPasswords((prev) => prev.filter((itemId) => itemId !== id));
  };

  const handleDelete = (id) => {
    setSelectedCredentialsId(id);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedCredentialsId(null);
    setDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    if (selectedCredentialsId) {
      var result = await deleteOutletUserExternalCredentials(
        selectedCredentialsId
      );

      if (result) {
        setOutletUserExternalCredentials((prevCredentials) =>
          prevCredentials.filter(
            (credentials) => credentials.id !== selectedCredentialsId
          )
        );
        DisplayMessage(
          resources["OutletUserExternalCredentialsWereDeletedWithSuccess"],
          "success"
        );
      }
    }
    setSelectedCredentialsId(null);
    closeDeleteModal();
  };

  useEffect(() => {
    const fetchOutletUserExternalCredentials = async () => {
      setLoading(true);
      const data = await getOutletUserExternalCredentials();
      if (data) {
        const formattedData = data.map(
          ({
            id,
            application,
            isDefault,
            outletId,
            username,
            createdById,
            createdByName,
            createdOn,
            createdByAvatar,
          }) => ({
            id,
            application,
            isDefault,
            outletId,
            username,
            password: "*********",
            outletName: outlets.find((item) => item.id === outletId)
              .description,
            createdById,
            createdByName,
            createdOn,
            createdByAvatar,
          })
        );
        setOutletUserExternalCredentials(formattedData);
      } else {
        setOutletUserExternalCredentials([]);
      }
      setLoading(false);
    };

    if (isFirstTime || refreshGrid) {
      fetchOutletUserExternalCredentials();
      setIsFirstTime(false);
    }
  }, [isFirstTime, refreshGrid, outlets]);

  return (
    <Box>
      <Box className="position-relative py-4">
        <Header
          title={resources["OutletUserExternalCredentials"]}
          loading={loading}
        />
        {!loading && (
          <>
            <AddItemButton
              title={resources["AddOutletUserExternalCredentials"]}
              linkPath={"/outletuserexternalcredentials/new"}
              state={{
                outlets: getAvailableOutlets(),
              }}
              hidden={!canCreateCredentials()}
            />
            <TableComponent
              resources={resources}
              data={outletUserExternalCredentials}
              tableSettings={tableSettings}
            />
          </>
        )}
        <DeleteConfirmationModal
          onConfirm={confirmDelete}
          resources={resources}
          areYouSureMessage={resources["AreYouSureYouWantToDeleteCredentials"]}
          open={deleteModalOpen}
          onClose={closeDeleteModal}
          className={classes.modal}
        />
      </Box>
    </Box>
  );
};

export default ExternalCredentialsTable;
