import { DisplayMessage } from "../helpers";
import { DeleteRequest, GetRequest, UpdateRequest } from "../requestHelpers";
import { HttpMethods } from "../../utils/constants";
import {
  CreateUpdateOutletUserExternalCredentialsUrl,
  GetOutletUserExternalCredentialsUrl,
  DeleteOutletUserExternalCredentialsUrl,
  RevealOutletUserExternalCredentialsUrl,
} from "../../constants/apiUrls";

export const createUpdateOutletUserExternalCredentials = async (data) => {
  const response = await UpdateRequest(
    `${CreateUpdateOutletUserExternalCredentialsUrl}`,
    data,
    HttpMethods.Put
  );

  const result = await response.json();
  if(!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }

  return result;
}

export const getOutletUserExternalCredentials = async () => {
  const response = await GetRequest(GetOutletUserExternalCredentialsUrl);
  let result = await response.json();
  if(!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      if(result.errors[i] === "CredentialsNotFound") continue;
      DisplayMessage(result.errors[i], "error");
    }
    return null;
  }
  return result;
};

export const getOutletUserExternalCredentialsById = async (id) => {
  const response = await GetRequest(GetOutletUserExternalCredentialsUrl + `?id=${id}`);
  let result = await response.json();
  if(!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return null;
  }
  return result;
};

export const deleteOutletUserExternalCredentials = async (id) => {
  const response = await DeleteRequest(DeleteOutletUserExternalCredentialsUrl(id));

  if (!response.ok) {
    response.json().then((result) => {
      for (let i = 0; i < result.errors.length; i++) {
        DisplayMessage(result.errors[i], "error");
      }
    });
  }

  return response.ok;
};

export const revealOutletUserExternalCredentials = async (id) => {
  const response = await GetRequest(RevealOutletUserExternalCredentialsUrl(id));
  const result = await response.json();

  if(!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return null;
  }

  return result;
};
