import {
  InputLabel,
  Select,
  FormControl,
  Box,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { SelectTheme } from "../../../utils/utils.js";

const DropdownComponent = ({
  value,
  handleChange,
  options,
  labelText,
  id,
  disabled,
  helperText,
  error,
  placeholder,
  isMandatory = false,
}) => {
  const isEmptyValue = () => {
    return value === 0 || value === "";
  };

  const getEmptyOptionDefaultValue = () => {
    if (isEmptyValue()) {
      return (
        <option value={value} disabled={isMandatory}>
          {placeholder}
        </option>
      );
    }

    return (
      <option value="" disabled={isMandatory}>
        {placeholder}
      </option>
    );
  };

  const getClassColor = () => {
    if (value === "" || value === 0) {
      return "color-red";
    }

    return "color-grey"
  };

  return (
    <Box className="d-flex form-group">
      <ThemeProvider theme={SelectTheme}>
        <FormControl className="col-12 main-dropdown" variant="outlined">
          <InputLabel
            id={id + "_dropdown"}
            htmlFor={`${id}_selected`}
            shrink={true}
          >
            {labelText}
          </InputLabel>
          <Select
            notched
            displayEmpty
            disabled={disabled ?? false}
            native
            id={`${id}_selected`}
            value={value}
            label={labelText}
            onChange={handleChange}
          >
            {placeholder && getEmptyOptionDefaultValue()}
            {options.map(({ key, name }) => (
              <option key={`${id}-${key}`} value={key}>
                {name}
              </option>
            ))}
          </Select>
          {helperText && (
            <FormHelperText
              className={getClassColor()}
              error={error}
            >
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </ThemeProvider>
    </Box>
  );
};

export default DropdownComponent;
