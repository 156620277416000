import React from "react";
import { RestrictedRoute } from "../utils/RestrictedRoute";
import { Switch, Route } from "react-router";
import {
  NewOutletUserExternalCredentialsRoute,
  OutletUserExternalCredentialRoute,
} from "../constants/routePaths";
import ExternalCredentialsTable from "../components/OutletUserExternalCredentials/ExternalCredentials";
import ExternalCredentialsItem from "../components/OutletUserExternalCredentials/ExternalCredentialsItem";

export default function OutletUserExternalCredentials(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute
        path="/outletuserexternalcredentials"
        userPermissions={currentIdentity.permissions}
        routePermissions={[]}
        resources={resources}
        render={(props) => {
          return (
            <ExternalCredentialsTable
              resources={resources}
              refreshGrid={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.refreshGrid
              }
              outlets={currentIdentity.accessibleMoboOutlets}
            />
          );
        }}
      />
      <Switch>
        <Route
          exact
          path={NewOutletUserExternalCredentialsRoute}
          render={(props) => (
            <ExternalCredentialsItem
              resources={resources}
              outlets={props.location.state.outlets}
              userPermissions={currentIdentity.permissions}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={OutletUserExternalCredentialRoute}
          render={(props) => (
            <ExternalCredentialsItem
              resources={resources}
              outlets={currentIdentity.accessibleMoboOutlets}
              outletUserExternalCredentials={
                props.location?.state?.outletUserExternalCredentials
              }
              pathId={props.match?.params?.outletUserExternalCredentialsId}
              userPermissions={currentIdentity.permissions}
              {...props}
            />
          )}
        />
      </Switch>
    </>
  );
}
