import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Loader from '../../Loader.js';

import Rodal from 'rodal';
import Paper from '@material-ui/core/Paper';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DisplayMessage } from '../../../services/helpers.js';

import Form from 'react-bootstrap/Form';
import FormControl from '@material-ui/core/FormControl';
import { SelectTheme, LocalizationTheme } from '../../../utils/utils.js';
import { ThemeProvider, InputLabel, Select, CircularProgress } from "@material-ui/core";
import { sortTableStringsNotCaseSensitive } from '../../../utils/tableSorting.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default class WorkPreparationsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataGrid: [],
            fetchingData: true,
            moboOutletRoles: [],
            showDelete: false,
            deletedItem: 0,
            moboOutletId: 0,
            requestInProgress: false,
            redirect: null,
        };
    }

    componentDidMount() {
        this.getMoboOutletRoles();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.moboOutletId !== undefined) {
            if (newProps.moboOutletId > 0) {
                this.getMoboOutletRoles(newProps.moboOutletId);
                this.setState({ redirect: null });
            } else {
                this.getMoboOutletRoles();
            }
        }
    }

    getMoboOutletRoles = (moboOutletId) => {
        var accessibleMoboOutlets = this.props.accessibleMoboOutlets;
        var moboOutletId = moboOutletId != undefined || moboOutletId == 0 ? moboOutletId : accessibleMoboOutlets[0].id;

        fetch('/api/mobopro/v1/workpreparations/mobooutletroles',
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {

                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                }
                else {
                    response.json().then(result => {

                        const moboOutletRoles = [];

                        result.forEach(function (item) {
                            var moboOutlet = item.moboOutletId !== undefined && item.moboOutletId !== null ? accessibleMoboOutlets.find(x => x.id === item.moboOutletId) : null;
                            var moboOutletDesc = moboOutlet !== undefined && moboOutlet !== null ? moboOutlet.description : "";

                            moboOutletRoles.push({
                                id: item.id,
                                moboOutletId: item.moboOutletId,
                                outletName: moboOutletDesc,
                                description: item.description
                            });
                        });

                        this.filterMoboOutletRoles(moboOutletRoles, moboOutletId);
                        this.setState({ moboOutletRoles: moboOutletRoles, moboOutletId: moboOutletId });
                    });
                }
            });
    }

    getData = (moboOutletRoles) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;

        initialData.columns = [
            { 
                title: resources["Outlet"],
                field: 'outletName',
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableStringsNotCaseSensitive(a.outletName, b.outletName) 
            },
            { 
                title: resources["Role"],
                field: 'description',
                customSort: (a, b) => sortTableStringsNotCaseSensitive(a.description, b.description)
            },
        ];

        var actions = [
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];

        initialData.actions = actions;
        initialData.data = moboOutletRoles;

        this.setState({
            dataGrid: initialData,
            fetchingData: false
        });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeleteRole = () => {
        var resources = this.props.resources;

        if (this.state.deletedItem > 0) {
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/workpreparations/mobooutletroles/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                    this.setState({ requestInProgress: false, showDelete: false, deletedItem: undefined });

                }
                else {
                    DisplayMessage(resources["RoleWasDeleted"], "success");
                    this.setState({ requestInProgress: false, showDelete: false, deletedItem: undefined });
                    this.getMoboOutletRoles(this.state.moboOutletId);
                }
            });
        }
    }

    filterMoboOutletRoles = (moboOutletRoles, moboOutletId) => {
        let filteredRoles = moboOutletId > 0 ? moboOutletRoles.filter(mr => mr.moboOutletId == moboOutletId) : moboOutletRoles;
        this.getData(filteredRoles);
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    handleAddNewRole = () => {
        this.setState({ moboOutletId: this.state.moboOutletId, redirect: `workpreparations/new` });
    }

    handleChangeOutlet = e => {
        var moboOutletId = parseInt(e.target.value);
        this.setState({ moboOutletId: moboOutletId }, () => this.getMoboOutletRoles(moboOutletId));
    };


    render() {
        let resources = this.props.resources;
        const { redirect, dataGrid, fetchingData, moboOutletId } = this.state;
        if (redirect) {
            return <Redirect to={{
                pathname: redirect
            }} />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["WorkPreparationRoles"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown">
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider >
                                <Select
                                    id="tag-outlet"
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.moboOutletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/workpreparations/new", state: { moboOutletId: this.state.moboOutletId, accessibleMoboOutlets: this.state.accessibleMoboOutlets } }} style={{ textDecoration: 'none' }}>
                                <Button id="addNew" color="primary" variant="contained" >
                                    <i className="fas fa-plus mr-2" title={resources["AddNewRoleTitle"]} />
                                    {resources["AddNewRole"]}
                                </Button>
                            </Link>
                        </div>

                        <div style={{ clear: "both", }}></div>
                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper >
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteRole"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button id="btnCancelDelete" className="btn btn-secondary" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={this.handleDeleteRole} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}