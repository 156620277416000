import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, Paper, Select, TextField, ThemeProvider, Typography } from '@material-ui/core';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, GetFormattedDate } from '../../../../services/helpers.js';
import { Dialog, AppBar, Toolbar, Checkbox, FormControlLabel, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom';
import { SelectTheme } from '../../../../utils/utils.js';
import defaultavatar from '../../../../defaultavatar.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class LinkAgendaNew extends Component {

    constructor(props) {
        super(props);

        let receivedOutletId;

        if(this.props.location?.state?.outletId !== undefined)
        {
            receivedOutletId = parseInt(this.props.location?.state?.outletId)
        }
        else if(this.props.match.params.outletId){
            receivedOutletId = parseInt(this.props.match.params.outletId)
        }

        this.state = {
            id: 0,
            loading: false,
            moboOutletId: receivedOutletId,

            outletUserId: 0,

            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",
            outletUsers: this.props.outletUsers !== undefined ? this.props.outletUsers : [],
            editedLinkAgendaId: this.props.linkagendaId,

            agendaId: 0,
            agendas: this.props.agendas !== undefined ? this.props.agendas : [],

            activeFrom: "",
            activeTill: "",
            hasNoAvailability: false,
            requestInProgress: false,
            openEditPopup: true,
            showEditLinkAgenda: false,
            redirect: null,
            linkAgendasWasCreated: false,
            linkAgendasItems: this.props.linkAgendasItems !== undefined ? this.props.linkAgendasItems : [],
        };
    }

    componentDidMount() {
        if (this.props.accessibleMoboOutlets !== undefined) {
            var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
            if (this.state.moboOutletId === undefined) this.setState({ moboOutletId: outlets[0].id });
        }

        if (this.state.outletUsers.length < 1) this.getOutletUsers();
        if (this.state.agendas.length < 1) this.getAgendas();

        if (this.state.editedLinkAgendaId !== undefined) {

            if (this.props.accessibleMoboOutlets != undefined) {
                var outletIdExists = false;
                this.props.accessibleMoboOutlets.forEach(element => {
                    if (element.id === this.state.moboOutletId) outletIdExists = true;
                });
                if (outletIdExists === false) {
                    this.setState({ loading: false, redirect: "/" });
                }
                else {
                    this.getLinkAgendaById(this.state.linkAgendasItems, parseInt(this.state.editedLinkAgendaId), this.state.moboOutletId);
                }
            }
        }
    }

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked, requestInProgress: false });
    };

    handleActiveFrom = e => {
        this.setState({ activeFrom: e.target.value, requestInProgress: false });
    };

    handleActiveTill = e => {
        this.setState({ activeTill: e.target.value, requestInProgress: false });
    };

    handleSubmit = () => {
        if (this.state.id == 0) {
            this.setState({ requestInProgress: true }, () => this.saveNewLinkAgenda());
        }
        else {
            this.setState({ requestInProgress: true }, () => this.saveEditedLinkAgenda());
        }
    }

    getOutletUsers() {
        var url = '/api/mobopro/v1/outletusers?includedeleted=false';
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    }
                    else {
                        const outletUsersInfo = [];
                        data.forEach(function (item) {
                            var outletUserName = item.firstName + " " + item.lastName;
                            outletUsersInfo.push({
                                id: item.id,
                                name: outletUserName
                            });
                        });

                        if (outletUsersInfo != undefined) {
                            var outletUsersInfoSorted = outletUsersInfo.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
                            this.setState({ outletUsers: outletUsersInfoSorted });
                        }
                    }
                })
            });
    }

    getLinkAgendaById = (linkAgendasItems, editedLinkAgendaId, outletId) => {

        if (linkAgendasItems !== [] && linkAgendasItems.length > 0) {
            linkAgendasItems.forEach(item => {
                if (item.id === editedLinkAgendaId) {
                    this.setState({
                        id: item.id,
                        activeFrom: GetFormattedDate(item.activeFrom),
                        activeTill: GetFormattedDate(item.activeTill),
                        loading: false,
                        modifiedOn: item.modifiedOn,
                        modifiedByName: item.modifiedBy !== null ? item.modifiedBy.firstName + " " + item.modifiedBy.lastName : "",
                        modifiedByAvatar: item.modifiedBy.avatar,
                        moboOutletId: item.outlet.moboOutletId,
                        outletUserId: item.outletUser.outletUserId,
                        agendaId: item.workshopPlanningAgenda.id,
                        hasNoAvailability: item.hasNoAvailability
                    });
                }
            });
        } else {
            fetch(`/api/mobopro/v1/linkedworkshopagendas?moid=${outletId}`,
                {
                    method: 'get',
                    headers: new Headers({
                        'PublicKey': localStorage.getItem('publicKey'),
                        'Authorization-token': localStorage.getItem('authorizationToken'),
                    }),
                }).then(response => {
                    response.json().then(data => {
                        if (!response.ok) {
                            for (var i = 0; i < data.errors.length; i++) {
                                DisplayMessage(data.errors[i], "error");
                            }
                            
                        }
                        else {
                            var existsAgendaItem = false;
                            var item = null;

                            data.forEach(function (row) {
                                if (row.id === editedLinkAgendaId) {
                                    existsAgendaItem = true;
                                    item = row;
                                }
                            });

                            if (existsAgendaItem) {
                                this.setState({
                                    id: item.id,
                                    activeFrom: GetFormattedDate(item.activeFrom),
                                    activeTill: GetFormattedDate(item.activeTill),
                                    loading: false,
                                    modifiedOn: item.modifiedOn,
                                    modifiedByName: item.modifiedBy !== null ? item.modifiedBy.firstName + " " + item.modifiedBy.lastName : "",
                                    modifiedByAvatar: item.modifiedBy.avatar,
                                    moboOutletId: item.outlet.moboOutletId,
                                    outletUserId: item.outletUser.outletUserId,
                                    agendaId: item.workshopPlanningAgenda.id,
                                    hasNoAvailability: item.hasNoAvailability
                                });
                            }
                            else {
                                this.setState({ loading: false,open:false, redirect: "/" });
                            }
                        }
                    })
                })
        }
    }

    getAgendas() {
        var url = '/api/mobopro/v1/workshopagendas';
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(data => {

                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    }
                    else {
                        const agendas = [];
                        data.forEach(function (item) {
                            agendas.push({
                                id: item.id,
                                description: item.description
                            });
                        });

                        if (agendas != undefined) {
                            var agendasSorted = agendas.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
                            this.setState({ agendas: agendasSorted });
                        }
                    }
                })

            });
    }

    validateData = (activeFrom, activeTill) => {
        var resources = this.props.resources;
        var isValid = true;

        if (this.state.outletUserId < 0) {
            DisplayMessage(resources["InvalidOutletUser"], "error");
            isValid = false;
        }

        if (this.state.agendaId < 0) {
            DisplayMessage(resources["InvalidAgenda"], "error");
            isValid = false;
        }
        if (activeFrom >= activeTill) {
            DisplayMessage(resources["InvalidEndDate"], "error");
            return false;
        }
        return isValid;
    }

    saveNewLinkAgenda = () => {
        let activeFrom = GetFormattedDate(this.state.activeFrom == "" ? undefined : this.state.activeFrom);
        let activeTill = GetFormattedDate(this.state.activeTill == "" ? undefined : this.state.activeTill);

        let resources = this.props.resources;
        if (!this.validateData(activeFrom, activeTill)) return;

        var json = JSON.stringify({
            outletUserId: this.state.outletUserId,
            moboOutletId: this.state.moboOutletId,
            workshopPlanningAgendaId: this.state.agendaId,
            activeFrom: activeFrom,
            activeTill: activeTill,
            hasNoAvailability: this.state.hasNoAvailability,
        });

        const url = "/api/mobopro/v1/linkedworkshopagendas";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewLinkAgendaWasSaved"], "success");
                this.setState({ requestInProgress: false, openEditPopup: false, redirect: "/linkagendas", agendasWasCreated: response.ok, linkAgendasWasCreated:true });
            }
        });
    }

    saveEditedLinkAgenda = () => {
        let activeFrom = GetFormattedDate(this.state.activeFrom == "" ? undefined : this.state.activeFrom);
        let activeTill = GetFormattedDate(this.state.activeTill == "" ? undefined : this.state.activeTill);

        let resources = this.props.resources;
        if (!this.validateData(activeFrom, activeTill)) return;

        var json = JSON.stringify({
            activeFrom: activeFrom,
            activeTill: activeTill,
            hasNoAvailability: this.state.hasNoAvailability,
        });

        const url = "/api/mobopro/v1/linkedworkshopagendas/" + this.state.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                response.json().then(data => {

                    this.setState({ updateInProgress: false, redirect: (response.ok ? '/linkagendas' : null), linkAgendaWasEdited: response.ok, linkAgendasWasCreated:true});
                    this.handleClose();

                    if (!response.ok) {
                        var errors = data.errors
                        for (var i = 0; i < errors.length; i++) {
                            DisplayMessage(errors[i], "error");
                        }
                    }
                    else {
                        DisplayMessage(resources["LinkAgendaWasSaved"], "success");                       
                    }
                })
            })
    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0) {
            return (<Form.Group className="d-flex">
                <div className="w-100 text-center">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={(this.state.modifiedByAvatar == null || this.state.modifiedByAvatar == "") ? defaultavatar : this.state.modifiedByAvatar} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }

        return;
    }

    handleClose = () => {
      this.setState({ loading: false, redirect: "/linkagendas" });
    }

    handleChangeOutlet = e => {
        this.setState({ moboOutletId: e.target.value, requestInProgress: false });
    };

    handleChangeOutletUser = e => {
        this.setState({ outletUserId: parseInt(e.target.value), requestInProgress: false });
    };

    handleChangeAgenda = e => {
        this.setState({ agendaId: parseInt(e.target.value), requestInProgress: false });
    };

    renderOutlets = () => {
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
        return outlets.map(x => <option key={x.id} value={x.id} > {x.description}</option>);
    }

    renderOutletUsers = () => {
        if (this.state.outletUsers === undefined) return;

        return this.state.outletUsers.map(x => <option key={x.id} value={x.id.toString()}> {x.name}</option>);
    }

    renderAgenda = () => {
        if (this.state.agendas === undefined) return;

        return this.state.agendas.map(x => <option key={x.id} value={x.id.toString()}> {x.description}</option>);
    }

    renderForm() {
        const { redirect, requestInProgress, hasNoAvailability, openEditPopup,
            editedLinkAgendaId, linkAgendasWasCreated, moboOutletId, outletUserId,
            agendaId, activeFrom, activeTill } = this.state;
        const { resources } = this.props;
        var isDisabled = editedLinkAgendaId > 0;
        var helperTextActiveFrom = activeFrom === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextActiveTill = activeTill === "" ? "helper-text-red" : "helper-text-grey";


        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { linkAgendasWasCreated, refreshGrid:linkAgendasWasCreated } }} /> }


        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        return (
            <Dialog fullScreen open={openEditPopup} onClose={this.handleClose} TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit} >

                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton edge="start" id="close-edit-agenda" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {editedLinkAgendaId !== undefined ? resources["EditLinkAgenda"] : resources["AddNewLinkAgendaTitle"]}
                            </Typography>
                            <Button id="linked-indicators-save" color="inherit" type="submit" disabled={requestInProgress}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>

                    <div className="container">
                        <div className="py-4 grey">
                            <Paper elevation={3} className="p-4 mt-3 mb-3">
                                <Form.Group className="pt-3" style={{ display: "flex" }}>
                                    <div className="col-md-3"></div>
                                    <FormControl disabled={isDisabled} className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="linked-agendas-outlet">{resources["Outlet"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            selectedId={moboOutletId}
                                            label={resources["Outlet"]}
                                            value={moboOutletId}
                                            inputProps={{
                                                name: 'moboOutletId',
                                                id: 'linked-agendas-outlet',
                                            }}>
                                            {this.renderOutlets()}
                                        </Select>
                                    </FormControl>
                                </Form.Group>
                                <Form.Group style={{ display: "flex" }}>
                                    <div className="col-md-3"></div>
                                    <FormControl disabled={isDisabled} className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutletUser}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="linked-agendas-outlet-user">{resources["OutletUser"]}</InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            selectedId={outletUserId}
                                            label={resources["OutletUser"]}
                                            value={outletUserId}
                                            inputProps={{
                                                name: 'outletUserId',
                                                id: 'linked-agendas-outlet-user',
                                            }}>
                                            <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                            {this.renderOutletUsers()}
                                        </Select>
                                        <FormHelperText className={this.state.outletUserId < 1 ? 'color-red' : 'color-grey'} >{resources["OutletUserHelperText"]}</FormHelperText>
                                    </FormControl>
                                </Form.Group>

                                <Form.Group style={{ display: "flex" }}>
                                    <div className="col-md-3"></div>
                                    <FormControl disabled={isDisabled} className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeAgenda}>
                                        <ThemeProvider theme={SelectTheme}>
                                            <InputLabel htmlFor="linked-agendas-agenda" >{resources["Agenda"]} </InputLabel>
                                        </ThemeProvider>
                                        <Select
                                            native
                                            selectedId={this.state.agendaId}
                                            label={`${resources["Agenda"]}`}
                                            value={this.state.agendaId}
                                            inputProps={{
                                                name: 'agendaId',
                                                id: 'linked-agendas-agenda',
                                            }}>
                                            <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                            {this.renderAgenda()}
                                        </Select>
                                        <FormHelperText className={this.state.agendaId < 1 ? 'color-red' : 'color-grey'}>{resources["AgendaHelperText"]}</FormHelperText>
                                    </FormControl>
                                </Form.Group>

                                <Form.Group style={{ display: "flex", width: "100%", justifyContent: "center" }}  >
                                    <FormControl className={helperTextActiveFrom}>
                                        <TextField id="dateActiveFrom" className="white-date mr-4" label={resources["ActiveFrom"]}
                                            helperText={resources["PleasePickADate"]}
                                            name="activeFrom" variant="outlined" type="date"
                                            required onChange={this.handleActiveFrom} value={activeFrom} InputLabelProps={{ shrink: true, required: false }}
                                            inputProps={{min: "2014-09-08", step: 7 }} />
                                    </FormControl>
                                    <FormControl className={helperTextActiveTill}>
                                        <TextField id="dateActiveTill" className="white-date" label={resources["ActiveTill"]}
                                            helperText={resources["PleasePickADate"]}
                                            name="activeTill" variant="outlined" type="date"
                                            required onChange={this.handleActiveTill} value={activeTill} InputLabelProps={{ shrink: true, required: false }}
                                            inputProps={{min: "2014-09-07", step: 7 }} />
                                    </FormControl>
                                </Form.Group>
                                <Form.Group className="d-flex" >
                                    <div className="col-md-3 "> </div>
                                    <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                                        control={<Checkbox id="agendas-has-no-availability" checked={hasNoAvailability} onChange={this.handleChangeCheckBox} name="hasNoAvailability" color="primary" />}
                                        label={resources["HasNoAvailability"]} />
                                    <div className="col-md-4"> </div>
                                </Form.Group>
                                {this.renderModifiedByInfo()}
                            </Paper>
                        </div>
                    </div>
                </Form>
            </Dialog >
        );
    }

    render() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>)

        return (
            <div className={"pt-4 grey text-center" + (this.state.loading ? 'fadeOut' : 'fadeIn')} >

                <div className="container">
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}