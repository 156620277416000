import { Box, CircularProgress, Dialog, Paper } from "@material-ui/core";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import DelayedRedirect from "../../../utils/DelayedRedirect";
import { Transition } from "../../../utils/ModalHelpers";
import { DisplayMessage } from "../../../services/helpers";
import {
  createAssetClass,
  updateAssetClass,
  getAssetClassById,
} from "../../../services/AssetClasses/assetClassApisService";
import { AssetClassesRoute } from "../../../constants/routePaths";
import AppBarComponent from "../../Common/Headers/AppBarComponent";
import DropdownComponent from "../../Common/Dropdowns/DropdownComponent";
import TextFieldComponent from "../../Common/Inputs/TextFieldComponent";
import DecimalFieldComponent from "../../Common/Inputs/DecimalFieldComponent";
import NumericFieldComponent from "../../Common/Inputs/NumericFieldComponent";
import CheckboxComponent from "../../Common/Checkboxes/CheckboxComponent";
import FormGroup from "@material-ui/core/FormGroup";
import { GetSortedOptionsByName } from "./../../../services/helpers";
import ModifiedByInfo from "../../Common/Footers/ModifiedByInfo";
import { getAssetGroups } from "../../../services/AssetGroups/assetGroupApisService.js";

const AssetClassItem = ({ resources, assetClassId, assetGroupId }) => {
  const isEditMode = useMemo(
    () => assetClassId && assetClassId > 0,
    [assetClassId]
  );
  const [assetGroups, setAssetGroups] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState(
    assetGroupId ?? 0
  );
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [priceHour, setPriceHour] = useState(null);
  const [priceDay, setPriceDay] = useState(null);
  const [priceWeek, setPriceWeek] = useState(null);
  const [priceMonth, setPriceMonth] = useState(null);
  const [maximumMileage, setMaximumMileage] = useState(null);
  const [mileageSurplusCost, setMileageSurplusCost] = useState(null);
  const [isActive, setIsActive] = useState(true);

  const [requestIsValid, setRequestIsValid] = useState(false);
  const [modifiedBy, setModifiedBy] = useState({
    avatar: "",
    on: "",
    name: "",
  });

  const isRequestValid = useCallback(
    (displayError) => {
      const valid =
        selectedAssetGroupId >= 1 &&
        code &&
        code.trim() !== "" &&
        description &&
        description.trim() !== "";

      if (!valid && displayError) {
        if (selectedAssetGroupId < 1) {
          DisplayMessage(resources["InvalidAssetGroupId"], "error");
        }
        if (!code || code.trim() === "") {
          DisplayMessage(resources["InvalidCode"], "error");
        }
        if (!description || description.trim() === "") {
          DisplayMessage(resources["InvalidDescription"], "error");
        }
      }

      setRequestIsValid(valid);
      return valid;
    },
    [code, description, resources, selectedAssetGroupId]
  );

  const fetchAssetGroups = async () => {
    const data = await getAssetGroups(true);
    setAssetGroups(
      data?.map(({ id, description }) => ({ key: id, name: description })) || []
    );
  };

  const fetchAssetClass = useCallback(async () => {
    setRequestInProgress(true);
    const result = await getAssetClassById(assetClassId);
    if (result) {
      setSelectedAssetGroupId(result.assetGroup?.id);
      setCode(result.code);
      setDescription(result.description);
      setPriceDay(result.priceDay);
      setPriceHour(result.priceHour);
      setPriceMonth(result.priceMonth);
      setPriceWeek(result.priceWeek);
      setMaximumMileage(result.maximumMileage);
      setMileageSurplusCost(result.mileageSurplusCost);
      setIsActive(result.isActive);
      setModifiedBy({
        avatar: result.modifiedByAvatar,
        on: result.modifiedOn,
        name: result.modifiedByName,
      });
    } else {
      setRedirect(AssetClassesRoute);
    }
    setRequestInProgress(false);
  }, [assetClassId]);

  useEffect(() => {
    fetchAssetGroups();
    if (isEditMode) {
      fetchAssetClass();
    }
  }, [isEditMode, fetchAssetClass]);

  useEffect(() => {
    isRequestValid(false);
  }, [isRequestValid]);

  const handleClose = () => {
    setDialogOpen(false);
    setRedirect(AssetClassesRoute);
  };

  const handleSubmit = async () => {
    if (!isRequestValid(true)) return;

    setRequestInProgress(true);
    const body = isEditMode
      ? JSON.stringify({
          code,
          description,
          priceHour: priceHour ? parseFloat(priceHour) : null,
          priceDay: priceDay ? parseFloat(priceDay) : null,
          priceMonth: priceMonth ? parseFloat(priceMonth) : null,
          priceWeek: priceWeek ? parseFloat(priceWeek) : null,
          maximumMileage: maximumMileage,
          mileageSurplusCost: mileageSurplusCost
            ? parseFloat(mileageSurplusCost)
            : null,
          isActive,
        })
      : JSON.stringify({
          assetGroupId: selectedAssetGroupId > 0 ? selectedAssetGroupId : null,
          code,
          description,
          priceHour: priceHour ? parseFloat(priceHour) : null,
          priceDay: priceDay ? parseFloat(priceDay) : null,
          priceMonth: priceMonth ? parseFloat(priceMonth) : null,
          priceWeek: priceWeek ? parseFloat(priceWeek) : null,
          maximumMileage,
          mileageSurplusCost: mileageSurplusCost
            ? parseFloat(mileageSurplusCost)
            : null,
          isActive,
        });

    const result = isEditMode
      ? await updateAssetClass(assetClassId, body)
      : await createAssetClass(body);

    if (result) {
      setRefreshGrid(true);
      const successMessage = isEditMode
        ? resources["AssetClassWasUpdatedWithSuccess"]
        : resources["AssetClassWasCreatedWithSuccess"];
      DisplayMessage(successMessage, "success");
    }
    setRedirect(AssetClassesRoute);
  };

  const getAssetGroupsOptions = () => {
    const filteredAssetGroups = GetSortedOptionsByName(assetGroups);
    return filteredAssetGroups;
  };

  return (
    <Box>
      {redirect && (
        <DelayedRedirect
          to={{ pathname: redirect, state: { refreshGrid: refreshGrid } }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          idName="assetClass"
          title={
            isEditMode
              ? resources["EditAssetClass"]
              : resources["NewAssetClass"]
          }
          resources={resources}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          disabled={requestInProgress || !requestIsValid}
        />
        {requestInProgress ? (
          <Box className="centered-loader fadeIn">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="container fadeIn">
            <Box className="py-4 grey">
              <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Box className="d-flex form-group pt-3">
                  <Box className="col-3" />
                  <Box className="col-6">
                    <DropdownComponent
                      value={selectedAssetGroupId}
                      handleChange={(e) =>
                        setSelectedAssetGroupId(parseInt(e.target.value))
                      }
                      options={getAssetGroupsOptions(assetGroups)}
                      labelText={resources["AssetGroup"]}
                      id="groups"
                      disabled={isEditMode}
                      error={!isEditMode && selectedAssetGroupId === 0}
                      helperText={
                        isEditMode
                          ? ""
                          : resources["SelectionOfAssetGroupIsMandatory"]
                      }
                      placeholder={resources["Select"]}
                      isMandatory
                    />
                    <TextFieldComponent
                      value={code}
                      handleChange={(e) => setCode(e.target.value)}
                      labelText={resources["Code"]}
                      id="code"
                      maxLength={25}
                      helperText={resources["InputOfCodeIsMandatory"]}
                    />
                    <TextFieldComponent
                      value={description}
                      handleChange={(e) => setDescription(e.target.value)}
                      labelText={resources["Description"]}
                      id="description"
                      maxLength={50}
                      helperText={resources["InputOfDescriptionIsMandatory"]}
                    />
                    <Box display="flex" flexDirection="row">
                      <DecimalFieldComponent
                        value={priceHour}
                        handleChange={(e) => setPriceHour(e)}
                        labelText={resources["PricePerHour"]}
                        id="price-hour"
                        marginRight={2}
                        placeholderText="€"
                        startSign="€"
                      />
                      <DecimalFieldComponent
                        value={priceDay}
                        handleChange={(e) => setPriceDay(e)}
                        labelText={resources["PricePerDay"]}
                        id="price-day"
                        marginRight={2}
                        placeholderText="€"
                        startSign="€"
                      />
                      <DecimalFieldComponent
                        value={priceWeek}
                        handleChange={(e) => setPriceWeek(e)}
                        labelText={resources["PricePerWeek"]}
                        id="price-week"
                        marginRight={2}
                        placeholderText="€"
                        startSign="€"
                      />
                      <DecimalFieldComponent
                        value={priceMonth}
                        handleChange={(e) => setPriceMonth(e)}
                        labelText={resources["PricePerMonth"]}
                        id="price-month"
                        placeholderText="€"
                        startSign="€"
                      />
                    </Box>
                    <NumericFieldComponent
                      value={maximumMileage}
                      handleChange={(e) => {
                        const inputValue = e.target.value;
                        setMaximumMileage(
                          inputValue === "" ? null : parseInt(inputValue, 10)
                        );
                      }}
                      labelText={resources["MileageIncluded"]}
                      id="mileage-included"
                    />
                    <DecimalFieldComponent
                      value={mileageSurplusCost}
                      handleChange={(e) => setMileageSurplusCost(e)}
                      labelText={resources["MileageSurplus"]}
                      id="mileage-surplus"
                    />
                    <FormGroup>
                      <CheckboxComponent
                        label={resources["IsActive"]}
                        id={"asset-class-isActive"}
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                    </FormGroup>
                  </Box>
                  <Box className="col-3" />
                </Box>
                {isEditMode && (
                  <ModifiedByInfo
                    resources={resources}
                    modifiedByAvatar={modifiedBy.avatar}
                    modifiedOn={modifiedBy.on}
                    modifiedByName={modifiedBy.name}
                  />
                )}
              </Paper>
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default AssetClassItem;
