import React, { forwardRef, Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LocalizationTheme } from '../../../utils/utils.js';
import { DisplayMessage } from '../../../services/helpers.js';
import MaterialTable from 'material-table';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sortTableNumbers, sortTableStringsNotCaseSensitive } from '../../../utils/tableSorting.js';

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

export default class FirstAppointmentDateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataGrid: [],
      fetchingData: true,
      requestInProgress: false
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.refreshGrid) {
      this.getData();
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ fetchingData: true });
    let initialData = {};
    var resources = this.props.resources;

    initialData.columns = [
      { 
        title: resources["Outlet"],
        field: 'outlet',
        editable: 'never',
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.outlet, b.outlet) 
      },
      { 
        title: resources["NumberOfDays"],
        field: 'numberOfDays',
        customSort: (a, b) => sortTableNumbers(a.numberOfDays, b.numberOfDays) 
      }
    ];

    fetch(`/api/mobopro/v1/mobooutletblockeddays`,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {
        const currentRows = [];

        result.forEach(function (rowData) {
          currentRows.push({
            outlet: rowData.moboOutlet?.description,
            moboOutletId: rowData.moboOutlet?.moboOutletId,
            numberOfDays: rowData.nrOfBlockedDays
          });
        });

        initialData.data = currentRows;

        this.setState({
          dataGrid: initialData,
          fetchingData: false
        });

      });
  }

  handleSaveNumberOfBlockedDays(data) {
    if (data.numberOfDays !== "" && (0 >= data.numberOfDays || data.numberOfDays > 99 || isNaN(data.numberOfDays))) {
      DisplayMessage(this.props.resources["InvalidNumberOfDays"], "error");
      return;
    }

    let resources = this.props.resources;
    this.setState({ requestInProgress: true });
    const body = { nrOfBlockedDays: data.numberOfDays };

    const url = `/api/mobopro/v1/mobooutletblockeddays/${data.moboOutletId}`;

    fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      }),
      body: JSON.stringify(body),
    }).then(response => {
      if (!response.ok) {
        if (response.statusText.length > 0) {
          DisplayMessage(response.statusText, "error");
          return response.json();
        }

        for (var i = 0; i < response.errors.length; i++) {
          DisplayMessage(response.errors[i], "error");
        }
        this.setState({ requestInProgress: false })
      }
    }).then(result => {
      DisplayMessage(resources["NumberOfBlockedDaysSaved"], "success");
      this.getData();
      this.setState({ requestInProgress: false })
    });
  }

  render() {
    const { fetchingData } = this.state;
    const { resources } = this.props;

    return <div>
      <div className="position-relative py-4">
        <h3>{resources["FirstAppointmentDateTitle"]}</h3>
        <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
        <div style={{ clear: "both", }}></div>

        <Paper elevation={3} className="p-3 mt-3">
          <MuiThemeProvider theme={LocalizationTheme()}>
            <MaterialTable
              icons={tableIcons}
              title={""}
              localization={{
                header: { actions: resources["Actions"] },
                body: {
                  emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                  editTooltip: resources["EditTooltip"],
                  editRow: {
                    saveTooltip: resources["SaveTooltip"],
                    cancelTooltip: resources["CancelTooltip"]
                  }
                },
                pagination: {
                  firstTooltip: resources["FirstTooltip"],
                  lastTooltip: resources["LastTooltip"],

                  previousTooltip: resources["PreviousTooltip"],
                  nextTooltip: resources["NextTooltip"],

                  labelRowsPerPage: resources["LabelRowsPerPage"],
                  labelRowsSelect: resources["LabelRowsSelect"],
                }
              }}
              data={this.state.dataGrid.data}
              columns={this.state.dataGrid.columns}
              actions={this.state.dataGrid.actions}
              options={{
                emptyRowsWhenPaging: false,
                pageSize: 10,
                pageSizeOptions: [10, 15, 20],
                filtering: false,
                debounceInterval: 700,
                search: false
              }}
              editable={{
                onRowUpdate: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      this.handleSaveNumberOfBlockedDays(newData);
                      resolve();
                    }, 1000);

                  }),
              }}
            />
          </MuiThemeProvider>
        </Paper>
      </div>
    </div>
  }
}