import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";

const PasswordInputComponent = ({
  value = "",
  handleChange,
  labelText,
  id,
  maxLength,
  helperText,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <FormControl variant="outlined" sx={{ "& .MuiOutlinedInput-input": { paddingLeft: "0px" } }}>
        <InputLabel htmlFor={`password-${id}`}>{labelText}</InputLabel>
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          id={`password-${id}`}
          value={value}
          label={labelText}
          name={id}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={handleChange}
          inputProps={{ maxLength: maxLength }}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              size="small"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
        />
        <FormHelperText error={!value.trim()}>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default PasswordInputComponent;
