import React, { Component, forwardRef } from "react";
import { Link, Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../Loader.js";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Paper from "@material-ui/core/Paper";
import Form from "react-bootstrap/Form";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { LocalizationTheme } from "../../../../utils/utils.js";
import { DisplayMessage } from "../../../../services/helpers.js";
import FontDownloadRoundedIcon from "@material-ui/icons/FontDownloadRounded";
import { DeleteForever } from "@material-ui/icons";
import { SelectTheme } from "../../../../utils/utils.js";
import { ThemeProvider } from "@material-ui/core";
import Block from "@material-ui/icons/Block";
import { sortTableColors, sortTableNumbers, sortTableStringsNotCaseSensitive } from "../../../../utils/tableSorting.js";
import ColorChip from "../../../Common/ColorChip/ColorChip.js";
import { isValidColor } from "../../../../utils/isValidColor.js";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  FontDownloadRoundedIcon: forwardRef((props, ref) => (
    <FontDownloadRoundedIcon {...props} ref={ref} />
  )),
};

export default class DossierStacksTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outletId: 0,
      dataGrid: [],
      currentDossierStackId: this.props.currentDossierStackId,
      dossierStackId: 0,
      fetchingData: true,
      showDelete: false,
      showUnDelete: false,
      deletedItem: 0,
      accessibleMoboOutlets: [],
      editedDossierStackId: undefined,
      editedDossierStack: undefined,
      showEditDossierStack: false,
      requestInProgress: false,
      dossierStacks: [],
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.refreshGrid && newProps.outletId !== undefined) {
      this.setState({
        outletId: newProps.outletId,
      });
      this.getData(newProps.outletId);
    } else {
      if (newProps.refreshGrid) {
        this.getData();
      }
    }
    this.setState({ redirect: null });
  }

  componentDidMount() {
    this.getData();
  }

  getData = (outletId) => {
    this.setState({ fetchingData: true });
    let initialData = {};
    var resources = this.props.resources;
    var accessibleMoboOutlets = this.props.accessibleMoboOutlets;
    var url = `/api/mobopro/v1/dossierstacks?isSettings=true`;
    if (outletId !== undefined && outletId > 0) {
      url = url + `&outletId=` + outletId;
    }

    initialData.columns = [
      {
        title: resources["Color"],
        field: "colorHex",
        width: "15%",
        render: (rowData) => <ColorChip colorHex={rowData.colorHex} id={isValidColor(rowData.colorHex) ? `color-${rowData.colorHex}-${rowData.id}` : `color-none-${rowData.id}`} />,
        customSort: (a, b) => sortTableColors(a.colorHex, b.colorHex)
      },
      {
        title: resources["Outlet"],
        width: "20%",
        field: "moboOutletDescription",
        filtering: false,
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.moboOutletDescription, b.moboOutletDescription)
      },
      {
        title: resources["Description"],
        width: "40%",
        field: "description",
        filtering: false,
        customSort: (a, b) => sortTableStringsNotCaseSensitive(a.description, b.description)
      },
      {
        title: resources["CountOfDossiers"],
        width: "40%",
        field: "countOfDossiers",
        filtering: false,
        customSort: (a, b) => sortTableNumbers(a.countOfDossiers, b.countOfDossiers),
      },
    ];

    initialData.actions = [
      {
        icon: () => <Edit />,
        tooltip: resources["Edit"],
        onClick: (event, rowData) =>
          this.setState({
            redirect: `dossierstacks/${rowData.id}`,
            editedDossierStackId: rowData.id,
          }),
      },

      (rowData) =>
        !rowData.isDeletedValue &&
          rowData.id === this.state.currentDossierStackId
          ? {
            icon: () => <DeleteForever />,
            tooltip: resources["YouCantDeleteDossierStack"],
          }
          : null,

      (rowData) =>
        !rowData.isDeletedValue &&
          rowData.id !== this.state.currentDossierStackId &&
          (rowData.countOfDossiers === 0 || rowData.countOfDossiers == null)
          ? {
            icon: () => <DeleteOutline />,
            tooltip: resources["Delete"],
            onClick: () => this.handleShowDelete(rowData.id),
          }
          : null,
    ];

    fetch(url, {
      method: "get",
      headers: new Headers({
        PublicKey: localStorage.getItem("publicKey"),
        "Authorization-token": localStorage.getItem("authorizationToken"),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then((result) => {
        const currentRows = [];
        const dossierStacks = [];

        result.forEach(function (rowData) {
          var moboOutlet =
            rowData.outletId !== undefined && rowData.outletId !== null
              ? accessibleMoboOutlets.find((x) => x.id === rowData.outletId)
              : null;
          var outletDescription =
            moboOutlet !== undefined && moboOutlet !== null
              ? moboOutlet.description
              : "";

          dossierStacks.push(rowData);
          currentRows.push({
            id: rowData.id,
            colorHex: rowData.colorHex,
            moboOutletDescription: outletDescription,
            description: rowData.description,
            countOfDossiers: rowData.countOfDossiers,
          });
        });

        initialData.data = currentRows;

        this.setState({
          dataGrid: initialData,
          fetchingData: false,
          dossierStacks: dossierStacks,
        });
      });
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false, deletedItem: 0 });
  };

  handleShowDelete = (itemId) => {
    this.setState({ showDelete: true, deletedItem: itemId });
  };

  handleDeleteDossierStack = () => {
    if (this.state.deletedItem > 0) {
      let resources = this.props.resources;
      this.setState({ requestInProgress: true });
      const url = "/api/mobopro/v1/dossierstacks/" + this.state.deletedItem;
      fetch(url, {
        method: "DELETE",
        headers: new Headers({
          PublicKey: localStorage.getItem("publicKey"),
          "Authorization-token": localStorage.getItem("authorizationToken"),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.statusText.length > 0) {
              DisplayMessage(response.statusText, "error");
              return response.json();
            }

            for (var i = 0; i < response.errors.length; i++) {
              DisplayMessage(response.errors[i], "error");
            }
          } else {
            DisplayMessage(resources["DossierStackWasDeleted"], "success");
            this.getData();
          }
        })
        .then(() => {
          this.setState({
            showDelete: false,
            deletedItem: 0,
            requestInProgress: false,
          });
          this.handleCloseDelete();
        });
    }
  };

  renderOutlets = () => {
    var outlets = this.props.accessibleMoboOutlets.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );
    if (this.props.accessibleMoboOutlets === undefined) return;

    var outletOptions = outlets.map((x) => (
      <option key={x.id} value={x.id}>
        {" "}
        {x.description}
      </option>
    ));
    return outletOptions;
  };

  handleChangeOutlet = (e) => {
    this.setState(
      { outletId: parseInt(e.target.value), requestInProgress: false },
      () => this.getData(this.state.outletId)
    );
  };

  render() {
    const { redirect, fetchingData, editedDossierStackId, outletId } =
      this.state;
    const { resources } = this.props;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: redirect,
            state: {
              editedDossierStackId: editedDossierStackId,
              editedDossierStack: this.state.dossierStacks.find(
                (t) => t.id === editedDossierStackId
              ),
              refreshGrid: true,
            },
          }}
        />
      );
    }

    return (
      <div>
        <div className="position-relative py-4">
          <h3>{resources["DossierStacksTitle"]}</h3>
          <div
            className={
              "offcentered-loader " + (fetchingData ? "fadeIn" : "fadeOut")
            }
          >
            <CircularProgress />
          </div>

          <div className={"mt-4 " + (fetchingData ? "fadeOut" : "fadeIn")}>
            <Form.Group className="main-dropdown">
              <FormControl
                className="col-md-12"
                variant="outlined"
                onChange={this.handleChangeOutlet}
              >
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-outlet-native-simple">
                    {resources["Outlet"]}
                  </InputLabel>
                </ThemeProvider>
                <Select
                  id="dossierStack-outlet-dropdown"
                  native
                  label={resources["Outlet"]}
                  value={outletId !== undefined ? outletId : 0}
                  inputProps={{
                    name: "outlet",
                    id: "dossierStack-outlet-dropdown",
                  }}
                >
                  <option value={0}>{resources["All"]}</option>
                  {this.renderOutlets()}
                </Select>
              </FormControl>
            </Form.Group>
            <div style={{ clear: "both" }}></div>
            <Form.Group className="main-dropdown"></Form.Group>
            <div style={{ float: "right", marginBottom: "10px" }}>
              <Link
                to={{
                  pathname: "/dossierstacks/new",
                  state: {
                    dossierStackId: this.state.dossierStackId,
                    accessibleMoboOutlets: this.state.accessibleMoboOutlets,
                    outletId: outletId,
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  id="add-dossierStack"
                  variant="contained"
                  color="primary"
                >
                  <i
                    className="fas fa-plus mr-2"
                    title={resources["AddNewDossierStack"]}
                  />
                  {resources["AddNewDossierStack"]}
                </Button>
              </Link>
            </div>
            <div style={{ clear: "both" }}></div>

            <Paper elevation={3} className="p-3 mt-3">
              <MuiThemeProvider theme={LocalizationTheme()}>
                <MaterialTable
                  icons={tableIcons}
                  title={""}
                  localization={{
                    header: { actions: resources["Actions"] },
                    body: {
                      emptyDataSourceMessage:
                        resources["NoRecordsToBeDisplayed"],
                      deleteTooltip: resources["DeleteTooltip"],
                      editTooltip: resources["EditTooltip"],
                    },
                    toolbar: {
                      searchPlaceholder: resources["Search"],
                      searchTooltip: resources["Search"],
                    },
                    pagination: {
                      firstTooltip: resources["FirstTooltip"],
                      lastTooltip: resources["LastTooltip"],

                      previousTooltip: resources["PreviousTooltip"],
                      nextTooltip: resources["NextTooltip"],

                      labelRowsPerPage: resources["LabelRowsPerPage"],
                      labelRowsSelect: resources["LabelRowsSelect"],
                    },
                  }}
                  data={this.state.dataGrid.data}
                  columns={this.state.dataGrid.columns}
                  actions={this.state.dataGrid.actions}
                  options={{
                    emptyRowsWhenPaging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20],
                  }}
                />
              </MuiThemeProvider>
            </Paper>
          </div>
          <div
            className={
              "loader-overlap position-absolute " +
              (this.state.fetchingData ? "fadeIn" : "fadeOut")
            }
          >
            <div className="container">
              <Loader />
            </div>
          </div>

          <Rodal
            visible={this.state.showDelete}
            onClose={this.handleCloseDelete}
            className="rodal"
            customStyles={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
            }}
          >
            <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
              {resources["AreYouSureYouWantToDeleteDossierStack"]}
            </div>
            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <button
                className="btn btn-secondary"
                id="btnCancelDelete"
                style={{
                  backgroundColor: "white",
                  color: "#53B3EA",
                  borderColor: "#53B3EA",
                }}
                onClick={this.handleCloseDelete}
                disabled={this.state.requestInProgress}
              >
                {resources["Cancel"]}
              </button>
              <button
                className="btn btn-primary"
                id="btnDelete"
                style={{
                  backgroundColor: "#53B3EA",
                  color: "white",
                  borderColor: "#53B3EA",
                }}
                onClick={this.handleDeleteDossierStack}
                disabled={this.state.requestInProgress}
              >
                {resources["Yes"]}
              </button>
            </div>
            <span className="rodal-close"></span>
          </Rodal>
        </div>
      </div>
    );
  }
}
