export const generateKey = async () => {
  const key = await  window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256, // 256-bit key
    },
    true,
    ["encrypt", "decrypt"]
  );
  return key;
};

export const encryptAes256Gcm = async (plaintext) => {
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encodedPlaintext = new TextEncoder().encode(plaintext);
  const secretKey = await generateKey();

  const ciphertext = await  window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    secretKey,
    encodedPlaintext
  );
  const exportedKey = await window.crypto.subtle.exportKey("raw", secretKey);
  const keyMaterial = new Uint8Array(exportedKey);
  const ciphertextMaterial = new Uint8Array(ciphertext);
  const mergedArray = new Uint8Array(keyMaterial.length + iv.length + ciphertextMaterial.length)
  mergedArray.set(keyMaterial, 0)
  mergedArray.set(iv,keyMaterial.length)
  mergedArray.set(ciphertextMaterial, iv.length + keyMaterial.length)

  return Buffer.from(mergedArray).toString("base64");
};

const makeKey = async (key) => {
  const importedKey = await window.crypto.subtle.importKey(
    "raw",
    key,
    {
      name: "AES-GCM",
    },
    false,
    ["decrypt"]
  );
  return importedKey;
};

export const decryptAes256Gcm = async (cypherText) => {
  const combined = Uint8Array.from(atob(cypherText), c => c.charCodeAt(0));

  const key = combined.slice(0, 32);
  const iv = combined.slice(32, 44);
  const message = combined.slice(44);

  const secretKey = await makeKey(key);

  const decryptedContent = await  window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv, tagLength: 128 },
    secretKey,
    message,
  );

  return  new TextDecoder().decode(decryptedContent);;
};

