import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme, SelectTheme } from '../../../../utils/utils.js';
import { DisplayMessage, GetDayOfWeekName } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';

import { ThemeProvider } from "@material-ui/core";
import { sortTableBooleans, sortTableDates, sortTableNumbers } from '../../../../utils/tableSorting.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class OpeningHoursTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataGrid: [],
            outletId: 0,
            fetchingData: true,
            showDelete: false,
            showCopy: false,
            deletedItem: 0,
            copiedOpeningHour: undefined,
            accessibleMoboOutlets: [],
            editedOpeningHourId: undefined,
            editedOpeningHour: undefined,
            showEditOpeningHour: false,
            requestInProgress: false,
            copiedDayOfWeek: "",
            daysOfWeek: [],
            checkedForData: false,
            openingHours: [],

        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid && newProps.outletId) {
            this.getData(newProps.outletId);
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlet = outlets[0].id;

        if (this.props.outletId !== undefined) {
            this.setState({ outletId: this.props.outletId });
        }
        else {
            this.setState({ outletId: outlet });
        }

        this.getData(outlet);
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value, copiedOutletId: 0 }, () => this.getData(this.state.outletId));
    };

    handleChangeCopiedDayOfWeek = e => {
        this.setState({ copiedDayOfWeek: e.target.value });
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    renderDaysOfWeek = () => {
        let resources = this.props.resources;
        var daysOfWeekNames = [];
        if (this.state.checkedForData === false || this.state.daysOfWeek.length === 7) return null;

        for (var i = 0; i < 7; i++) {

            if (this.state.daysOfWeek.length === 0 || this.state.daysOfWeek.indexOf(i) < 0) {
                daysOfWeekNames.push({ id: i, description: GetDayOfWeekName(i, resources) });
            }
        }
        daysOfWeekNames = daysOfWeekNames.sort((a, b) => (a.id > b.id) ? 1 : -1)

        if (this.state.copiedDayOfWeek === "" && daysOfWeekNames.length > 0) this.setState({ copiedDayOfWeek: daysOfWeekNames[0].id });

        var daysOfWeek = daysOfWeekNames.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return daysOfWeek;
    }

    getData = (outlet) => {
        this.setState({ fetchingData: true });

        if (outlet === undefined || outlet === "") {
            var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
            if (this.props.accessibleMoboOutlets === undefined) return;
            outlet = this.state.outletId > 0 ? this.state.outletId : outlets[0].id;
        }

        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            {
                title: resources["Day"],
                field: 'dayOfWeek',
                width: "20%",
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableNumbers(a.dayOfWeekNumber, b.dayOfWeekNumber)
            },
            {
                title: resources["OpenFrom"],
                field: 'workshopOpenFrom',
                width: "20%",
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableDates(a.workshopOpenFrom, b.workshopOpenFrom, "HH:mm")
            },
            {
                title: resources["OpenTill"],
                field: 'workshopOpenTill',
                width: "20%",
                options: { filter: true, sort: true },
                customSort: (a, b) => sortTableDates(a.workshopOpenTill, b.workshopOpenTill, "HH:mm")
            },
            { 
                title: resources["IsWorkshopClosed"],
                field: 'isWorkshopClosed',
                width: "20%",
                options: { filter: true, sort: true },
                render: rowData => rowData.isWorkshopClosed ? resources["Yes"] : resources["No"],
                customSort: (a, b) => sortTableBooleans(a.isWorkshopClosed, b.isWorkshopClosed)
            },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `openinghours/${rowData.moboOutletId}/${rowData.id}`, editedOpeningHourId: rowData.id, editedOpeningHour: this.state.openingHours.find(x => x.id === rowData.id) })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            },

            rowData => (this.state.dataGrid.data !== undefined && this.state.dataGrid.data.length !== 7 ? {
                icon: () => <FileCopyIcon />,
                tooltip: resources["Copy"],
                onClick: (event, rowData) => this.handleShowCopy(rowData)
            } : null),
        ]

        fetch('/api/mobopro/v1/mobooutletopeninghours/' + outlet,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {

                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    });
                    this.setState({
                        fetchingData: false
                    });
                }
                else {
                    response.json().then(result => {

                        const currentRows = [];
                        const openingHours = [];
                        var daysOfWeek = [];
                        result.forEach(function (rowData) {
                            openingHours.push(rowData);
                            daysOfWeek.push(rowData.dayOfWeek);
                            var dayOfWeekName = GetDayOfWeekName(rowData.dayOfWeek, resources);
                            currentRows.push({
                                id: rowData.id,
                                dayOfWeek: dayOfWeekName,
                                dayOfWeekNumber: rowData.dayOfWeek,
                                moboOutletId: rowData.moboOutletId,

                                workshopOpenFrom: rowData.workshopOpenFrom,
                                workshopOpenTill: rowData.workshopOpenTill,

                                workshopDropFrom: rowData.workshopDropFrom,
                                workshopDropTill: rowData.workshopDropTill,

                                workshopDropCloseNoonFrom: rowData.workshopDropCloseNoonFrom,
                                workshopDropCloseNoonTill: rowData.workshopDropCloseNoonTill,

                                onlineOpenFrom: rowData.onlineOpenFrom,
                                onlineOpenTill: rowData.onlineOpenTill,

                                isWorkshopClosed: rowData.isWorkshopClosed,
                            });
                        });

                        var filteredData = currentRows.sort((a, b) => (a.dayOfWeekNumber > b.dayOfWeekNumber) ? 1 : -1)

                        initialData.data = filteredData;

                        this.setState({
                            daysOfWeek: daysOfWeek,
                            dataGrid: initialData,
                            openingHours: openingHours,
                            fetchingData: false,
                            checkedForData: true,
                            outletId: parseInt(outlet)
                        });
                    });
                }
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleCloseCopy = () => {
        this.setState({ showCopy: false, copiedOpeningHour: undefined, copiedDescription: "" });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleShowCopy = (item) => {
        this.setState({ showCopy: true, copiedOpeningHour: item, copiedDescription: item.dayOfWeek, copiedDayOfWeek: "" });
    }

    handleDeleteOpeningHour = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/mobooutletopeninghours/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["OpeningHourWasDeleted"], "success");
                this.getData(this.state.outletId);
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();
            });
        }
    }

    handleCopyOpeningHour = () => {
        if (this.state.copiedOpeningHour === undefined) return;

        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        var copiedOpeningHour = this.state.copiedOpeningHour;

        var json = JSON.stringify({
            workshopOpenFrom: copiedOpeningHour.workshopOpenFrom === null ? "8:00" : copiedOpeningHour.workshopOpenFrom,
            workshopOpenTill: copiedOpeningHour.workshopOpenTill === null ? "17:00" : copiedOpeningHour.workshopOpenTill,
            workshopDropFrom: copiedOpeningHour.workshopDropFrom === null ? "8:00" : copiedOpeningHour.workshopDropFrom,
            workshopDropTill: copiedOpeningHour.workshopDropTill === null ? "16:00" : copiedOpeningHour.workshopDropTill,
            workshopDropCloseNoonFrom: copiedOpeningHour.workshopDropCloseNoonFrom,
            workshopDropCloseNoonTill: copiedOpeningHour.workshopDropCloseNoonTill,
            onlineOpenFrom: copiedOpeningHour.onlineOpenFrom,
            onlineOpenTill: copiedOpeningHour.onlineOpenTill,
            isWorkshopClosed: copiedOpeningHour.isWorkshopClosed,
            dayOfWeek: this.state.copiedDayOfWeek,
            moboOutletId: copiedOpeningHour.moboOutletId
        });

        const url = `/api/mobopro/v1/mobooutletopeninghours`;
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });
                }
                this.setState({ requestInProgress: false });
            })
            .then(result => {
                this.setState({
                    updateInProgress: false,
                    openingHourWasCreated: true,
                    redirect: "/openinghours",
                    showCopy: false,
                    copiedOpeningHour: undefined,
                    copiedDescription: "",
                    copiedDayOfWeek: "",
                }, () => this.getData());

                DisplayMessage(resources["OpeningHourWasCopied"], "success");
            });
    }

    render() {

        const { redirect, fetchingData, dataGrid } = this.state;
        const { resources } = this.props;
        const displayAddButton = dataGrid.data === undefined || dataGrid.data.length < 7 ? "block" : "none";


        if (redirect) { return <Redirect to={{ pathname: redirect, state: { editedOpeningHour: this.state.editedOpeningHour, outletId: this.state.outletId, refreshGrid: true } }} /> }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["OpeningHours"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="grid-outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'grid-outlined-outlet-native-simple',
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px", display: displayAddButton }}>
                            <Link to={{ pathname: `/openinghours/new`, state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-OpeningHour" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewOpeningHour"]} />
                                    {resources["AddNewOpeningHour"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteOpeningHour"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteOpeningHour} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                    <Rodal visible={this.state.showCopy} onClose={this.handleCloseCopy} className="rodal" style={{ height: "350px!important" }} >

                        <div className="modal-body" >{resources["CopyOpeningHour"]}</div>
                        <Form.Group style={{ display: "flex" }} onClick={this.handleTyping}>
                            <Form.Label className="col-md-3 text-left label-mt my-auto" >{resources["From"]}:  </Form.Label>
                            <Form.Label className="col-md-6 text-left label-mt my-auto pl-0 form-label" >{this.state.copiedDescription}  </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ display: "flex" }}>
                            <Form.Label className="col-md-3 text-left label-mt my-auto" >{resources["To"]}:  </Form.Label>
                            <FormControl className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeCopiedDayOfWeek}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="grid-outlined-dayOfWeek-native-simple">{resources["DayOfWeek"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="openinghours-dayOfWeek"
                                    native
                                    label={resources["DayOfWeek"]}
                                    value={this.state.copiedDayOfWeek}
                                    inputProps={{
                                        name: 'dayOfWeek',
                                        id: 'grid-outlined-dayOfWeek-native-simple',
                                    }}>
                                    {this.renderDaysOfWeek()}
                                </Select>
                            </FormControl>
                        </Form.Group>


                        <div className="modal-footer" style={{ borderTop: "1px solid white" }}>
                            <button className="btn btn-secondary" id="btnCancelCopy" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseCopy} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnCopy" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleCopyOpeningHour} disabled={this.state.requestInProgress}>{resources["Save"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}