import { Box, TextField, FormHelperText, Tooltip } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { ThemeProvider } from "@material-ui/core/styles";
import { SelectTheme } from "../../../utils/utils.js";

const TextFieldComponent = ({
  value,
  handleChange,
  labelText,
  id,
  maxLength,
  helperText,
  isValid = null,
  tooltipText,
  type = "text",
  disabled = false,
}) => {
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <ThemeProvider theme={SelectTheme}>
        <Box display={"flex"}>
          <TextField
            className="col-12"
            id={id}
            value={value ?? ""}
            label={labelText}
            variant="outlined"
            autoComplete={type === "password" ? "new-password" : "off"}
            name={id}
            type={type}
            onChange={handleChange}
            inputProps={{
              maxLength: maxLength,
            }}
            InputLabelProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "100%",
              },
            }}
            disabled={disabled}
          />
          {tooltipText && (
            <span
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Tooltip title={tooltipText}>
                <InfoIcon />
              </Tooltip>
            </span>
          )}
        </Box>

        {helperText && (
          <FormHelperText
            style={{ marginLeft: "14px", marginRight: "14px" }}
            error={isValid == null ? !value : !isValid}
          >
            {helperText}
          </FormHelperText>
        )}
      </ThemeProvider>
    </Box>
  );
};

export default TextFieldComponent;
