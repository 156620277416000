import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SelectTheme, LocalizationTheme } from '../../../../utils/utils.js';
import moment from 'moment';
import { ThemeProvider } from "@material-ui/core";
import { FormGroup, TextField } from '@material-ui/core';
import { sortTableBooleans, sortTableDates, sortTableStringsNotCaseSensitive } from '../../../../utils/tableSorting.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class BookingDateMarkersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            year: new Date().getFullYear(),
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
            selectedOutletsChecked: [],
            selectedOutletsCheckedName: "",
            accessibleMoboOutlets: [],
            requestInProgress: false,
            checkedForData: false
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData(newProps.outletId === 0 ? null : newProps.outletId, newProps.year === 0 ? null : newProps.year);
        }
        this.setState({ redirect: false });
    }

    componentWillMount() {
        this.getData();
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.selectedIndex === 0 ? 0 : e.target.value }, () => this.getData(this.state.outletId, this.state.year));
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    handleChangeYear = e => {
        if (e.target.value.length === 5) { return; }
        this.setState({ year: parseInt(e.target.value) }, () => this.getData(this.state.outletId, this.state.year));
    }

    getData = (outletId, year) => {
        const { culture } = this.props;
        moment.locale(culture);

        this.setState({ fetchingData: true });

        let initialData = {};
        var resources = this.props.resources;

        initialData.columns = [
            {
                title: resources["DateMarkersDateFrom"],
                width: "30%",
                field: 'dateFrom',
                filtering: false,
                customSort: (a, b) => sortTableDates(a.dateFrom, b.dateFrom, "DD-MM-yyyy")
            },
            {
                title: resources["DateMarkersDateTill"],
                width: "30%",
                field: 'dateTill',
                filtering: false,
                customSort: (a, b) => sortTableDates(a.dateTill, b.dateTill, "DD-MM-yyyy")
            },
            { 
                title: resources["Description"],
                width: "30%",
                field: 'description',
                filtering: false,
                customSort: (a, b) => sortTableStringsNotCaseSensitive(a.description, b.description) 
            },
            { 
                title: resources["IsClosed"],
                width: "10%",
                field: "isClosed",
                filtering: false,
                sortable: true,
                lookup: { true: resources["True"], false: resources["False"] },
                customSort: (a, b) => sortTableBooleans(a.isClosed, b.isClosed) 
            }
        ];

        initialData.actions = [
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];


        if (outletId === undefined || outletId === 0 || year === undefined) {
            this.setState({
                fetchingData: false, dataGrid: initialData
            });

            return;
        }

        fetch(`/api/mobopro/v1/bookingdatemarkers?outletid=${outletId}&year=${year}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                        this.setState({ fetchingData: false });
                    })
                }
                else {
                    response.json().then(result => {
                        const currentRows = [];
                        result.forEach(function (rowData) {

                            currentRows.push({
                                id: rowData.id,
                                outletId: rowData.moboOutlet,
                                dateFrom: `${moment(rowData.dateFrom).format('ddd DD-MM-yyyy')}`,
                                dateTill: `${moment(rowData.dateTill).format('ddd DD-MM-yyyy')}`,
                                isClosed: rowData.isClosure,
                                description: rowData.description
                            })
                        })

                        initialData.data = currentRows;

                        this.setState({
                            dataGrid: initialData,
                            fetchingData: false,
                            checkedForData: true,
                            outletId: outletId > 0 ? parseInt(outletId) : 0
                        });
                    })
                }
            })
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleDeleteDateMarker = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });

            const url = "/api/mobopro/v1/bookingdatemarkers/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        this.handleCloseDelete();
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                    this.handleCloseDelete();
                }
                else {
                    DisplayMessage(resources["BookingDateMarkerWasDeleted"], "success");
                    this.getData(this.state.outletId, this.state.year);
                    this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                    this.handleCloseDelete();
                }
            });
        }
    }


    render() {
        const { redirect, fetchingData, outletId, dataGrid, year } = this.state;
        const { resources } = this.props;
        var outletIsSelected = !(outletId === 0 || outletId === undefined);
        var helperTextYear = year === "" ? resources["YearHelperText"] : '';
        if (redirect) { return <Redirect to={{ pathname: redirect, state: { outletId: outletId === undefined || isNaN(outletId) ? 0 : outletId, bookingItems: dataGrid.data, refreshGrid: true, year: year } }} /> }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["DateMarkers"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                            <div className="col-1"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <FormGroup className=" col-sm-4">
                                    <TextField className="helper-text-red " id="year" name="year" value={year}
                                        type="number" label={resources["Year"]} helperText={helperTextYear}
                                        inputProps={{
                                            max: 2050, min: 2022
                                        }}
                                        variant="outlined" onChange={this.handleChangeYear} />
                                </FormGroup>
                            </ThemeProvider>

                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: `/bookingdatemarkers/new`, state: { outletId: this.state.outletId, year: this.state.year } }} style={{ textDecoration: 'none', 
                            pointerEvents: outletIsSelected ? '' : 'none'}}>
                                <Button disabled={!outletIsSelected}  id="add-datemarker" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewDateMarker"]} />
                                    {resources["AddNewDateMarker"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>
                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"]
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 15, 20],
                                        debounceInterval: 700
                                    }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}>
                        <div className="container"><Loader /> </div>
                    </div>
                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteBookingDateMarker"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteDateMarker} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>
                </div>
            </div>

        )
    }
}
